import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import equal from 'react-fast-compare';
import classnames from 'classnames';

export default class ActionsButton extends Component {
  static propTypes = {
    buttonActions: PropTypes.array.isRequired,
    splitButton: PropTypes.bool,
    showDropDown: PropTypes.bool,
  };

  static defaultProps = { splitButton: true, showDropDown: true };

  state = {
    lastAction: this.props.buttonActions.filter(Boolean)[0],
  };

  componentDidUpdate(prevProps) {
    const buttonActions = this.props.buttonActions.filter(Boolean);
    const prevButtonActions = prevProps.buttonActions.filter(Boolean);

    // updating selected rows afer data change
    if (buttonActions.length > 0 && !equal(buttonActions, prevButtonActions)) {
      const newLastAction = buttonActions.find(
        ({ id, title }) =>
          (id && this.state.lastAction.id === id) ||
          (title && this.state.lastAction.title === title),
      );
      this.setState({
        lastAction: newLastAction || this.props.buttonActions.filter(Boolean)[0],
      });
    }
  }

  render() {
    return this.state.lastAction ? (
      <div className={classnames('common-actions-button', this.props.className)}>
        <Dropdown as={ButtonGroup}>
          {this.props.splitButton && (
            <Button
              variant="secondary"
              size="sm"
              onClick={this.state.lastAction.handler}
              disabled={this.state.lastAction.disabled}
            >
              {typeof this.state.lastAction.title === 'object'
                ? this.state.lastAction.customIcon || this.state.lastAction.subTitle
                : this.state.lastAction.customIcon || this.state.lastAction.title}
            </Button>
          )}
          {this.props.showDropDown && (
            <>
              <Dropdown.Toggle
                split={this.props.splitButton}
                variant="secondary"
                size="sm"
                id="dropdown-split-basic"
              >
                {!this.props.splitButton &&
                  (this.state.lastAction.customIcon || this.state.lastAction.title)}
              </Dropdown.Toggle>
              <Dropdown.Menu align={{ sm: 'right' }} className="modifier">
                {this.props.buttonActions.filter(Boolean).map((item, index) =>
                  item.divider ? (
                    <Dropdown.Divider key={index} />
                  ) : (
                    <div key={index}>
                      {item.header && <Dropdown.Header>{item.header}</Dropdown.Header>}
                      <Dropdown.Item
                        onClick={() => {
                          this.setState({ lastAction: item });
                          item.handler();
                        }}
                        disabled={item.disabled}
                      >
                        {item.customIcon && item.customIcon}
                        {item.icon && (
                          <span className="fa-layers fa-fw mr-2" style={item.style}>
                            {item.icon.type === 'muiIcon' ? (
                              item.icon.component
                            ) : (
                              <FontAwesomeIcon icon={item.icon} />
                            )}
                            {item.icon2 && (
                              <FontAwesomeIcon
                                icon={item.icon2}
                                transform="shrink-5 up-4 right-6"
                              />
                            )}
                          </span>
                        )}
                        {item.title && <span>{item.title}</span>}
                      </Dropdown.Item>
                    </div>
                  ),
                )}
              </Dropdown.Menu>
            </>
          )}
        </Dropdown>
      </div>
    ) : null;
  }
}
