import { put, take, cancel, fork, select, call, delay } from 'redux-saga/effects';
import {
  VIEWING_UPDATE_ANNOTATION_TAGS_BEGIN,
  VIEWING_UPDATE_ANNOTATION_TAGS_SUCCESS,
  VIEWING_UPDATE_ANNOTATION_TAGS_FAILURE,
  VIEWING_UPDATE_ANNOTATION_TAGS_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import {
  withCurrentCaseId,
  selectCurrentFile,
  selectCurrentFolder,
  selectAnnotations,
  withCurrentUserId,
} from 'common/selectors';
import { replaceItemImmutable } from 'utils/arrays';
import { CASE_ADD_ANNOTATIONS, CASE_REMOVE_ANNOTATIONS } from 'features/case/redux/constants';
import { filterAnnotations } from './filterAnnotations';

export const updateAnnotationTags = createPromiseAction(VIEWING_UPDATE_ANNOTATION_TAGS_BEGIN);

export function dismissUpdateAnnotationTagsFeedback() {
  return {
    type: VIEWING_UPDATE_ANNOTATION_TAGS_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on VIEWING_UPDATE_ANNOTATION_TAGS_BEGIN actions
export function* doUpdateAnnotationTags(action) {
  const {
    tags,
    annotation,
    noDebounce,
    fileId,
    caseId,
    isAnnotations,
    currentUserId: userId,
  } = action.payload;
  if (!noDebounce) yield delay(process.env.REACT_APP_DEBOUNCE); // debounce

  const file = yield select(selectCurrentFile);
  const currentFolder = yield select(selectCurrentFolder);
  const annotations = yield select(selectAnnotations);

  const res = yield call(
    api.put,
    `/cases/${caseId}/${
      Object.keys(currentFolder).length > 0 ? `folders/${currentFolder.id}/` : ''
    }files/${file || fileId}/annotations/${annotation.id}/${isAnnotations ? 'annotations' : 'tags'}`,
    tags,
  );

  if (res && res.error) {
    yield put({
      type: VIEWING_UPDATE_ANNOTATION_TAGS_FAILURE,
      feedback: {
        message: 'feedback.updateAnnotationTagsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: VIEWING_UPDATE_ANNOTATION_TAGS_SUCCESS,
    data: { tags: res, annotation },
    userId,
  });
  if (
    currentFolder &&
    tags.includes(currentFolder.id) &&
    !annotation.tags.find((item) => item.id === currentFolder.id)
  ) {
    //if annotation is open and creating tag, add new annotation to grid
    yield put({
      type: CASE_ADD_ANNOTATIONS,
      data: {
        file: null,
        fileId: file || fileId,
        id: annotation.id,
        note: annotation.annotation.data.comment || '',
        page: null,
        selectedText: annotation.annotation.selectedText || '',
        tagType: 'tag-annotation',
        annotation: {
          ...annotation,
          tags: res,
        },
      },
    });
  } else if (
    currentFolder &&
    !tags.includes(currentFolder.id) &&
    annotation.tags.find((item) => item.id === currentFolder.id)
  ) {
    //if annotation is open and unassigning tag, then remove annotation from grid
    yield put({
      type: CASE_REMOVE_ANNOTATIONS,
      data: {
        index: annotations.findIndex(
          (item) => item.annotation && item.annotation.id === annotation.id,
        ),
      },
    });
  }
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateAnnotationTags() {
  //debounce
  let task;
  while (true) {
    const action = yield take(updateAnnotationTags);
    if (task && !action.noDebounce) {
      yield cancel(task);
    }
    task = yield fork(withCurrentUserId(withCurrentCaseId(doUpdateAnnotationTags)), action);
  }
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_UPDATE_ANNOTATION_TAGS_BEGIN:
      return {
        ...state,
        updateAnnotationTagsPending: true,
        updateAnnotationTagsFeedback: null,
      };

    case VIEWING_UPDATE_ANNOTATION_TAGS_SUCCESS: {
      const indexOfTheAnnotationToUpdate = state.annotations.findIndex(
        (x) => x.id === action.data.annotation.id,
      );
      const newAnnotations = replaceItemImmutable(
        state.annotations,
        { ...state.annotations[indexOfTheAnnotationToUpdate], tags: action.data.tags },
        indexOfTheAnnotationToUpdate,
      );
      return {
        ...state,
        updateAnnotationTagsPending: false,
        updateAnnotationTagsFeedback: action.feedback,
        annotations: newAnnotations,
        filteredAnnotations: filterAnnotations(newAnnotations, state.updatedFilters, action.userId),
      };
    }

    case VIEWING_UPDATE_ANNOTATION_TAGS_FAILURE:
      return {
        ...state,
        updateAnnotationTagsPending: false,
        updateAnnotationTagsFeedback: action.feedback,
      };

    case VIEWING_UPDATE_ANNOTATION_TAGS_DISMISS_FEEDBACK:
      return {
        ...state,
        updateAnnotationTagsFeedback: null,
      };

    default:
      return state;
  }
}
