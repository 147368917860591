import { put, call, select, takeEvery } from 'redux-saga/effects';
import {
  CASE_UPDATE_ANNOTATIONS_BEGIN,
  CASE_UPDATE_ANNOTATIONS_SUCCESS,
  CASE_UPDATE_ANNOTATIONS_FAILURE,
  CASE_UPDATE_ANNOTATIONS_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { selectCurrentFolder, withCurrentCaseId } from 'common/selectors';
import { replaceItemImmutable } from 'utils/arrays';

export function updateAnnotations({ annotation }) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: CASE_UPDATE_ANNOTATIONS_BEGIN,
    payload: { annotation },
  };
}

export function dismissUpdateAnnotationsFeedback() {
  return {
    type: CASE_UPDATE_ANNOTATIONS_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPDATE_ANNOTATIONS_BEGIN actions
export function* doUpdateAnnotations(action) {
  const {
    payload: { annotation, caseId },
  } = action;
  const { note } = annotation;
  const currentFolder = yield select(selectCurrentFolder);

  const res = yield call(
    api.put,
    `/cases/${caseId}/tags/${currentFolder.id}/locations/${annotation.id}`,
    { note },
  );

  if (res && res.error) {
    return yield put({
      type: CASE_UPDATE_ANNOTATIONS_FAILURE,
      feedback: {
        message: 'feedback.updateAnnotationsFailure',
        error: res.error,
        retryAction: action,
      },
    });
  }

  yield put({
    type: CASE_UPDATE_ANNOTATIONS_SUCCESS,
    data: { annotation: res },
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateAnnotations() {
  yield takeEvery(CASE_UPDATE_ANNOTATIONS_BEGIN, withCurrentCaseId(doUpdateAnnotations));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_ANNOTATIONS_BEGIN:
      return {
        ...state,
        updateAnnotationsPending: true,
        updateAnnotationsFeedback: null,
      };

    case CASE_UPDATE_ANNOTATIONS_SUCCESS: {
      const indexOfAnnotationToUpdate = state.annotations.findIndex(
        (x) => x.id === action.data.annotation.id,
      );
      return {
        ...state,
        updateAnnotationsPending: false,
        updateAnnotationsFeedback: action.feedback,
        annotations: replaceItemImmutable(
          state.annotations,
          action.data.annotation,
          indexOfAnnotationToUpdate,
        ),
      };
    }

    case CASE_UPDATE_ANNOTATIONS_FAILURE:
      return {
        ...state,
        updateAnnotationsPending: false,
        updateAnnotationsFeedback: action.feedback,
      };

    case CASE_UPDATE_ANNOTATIONS_DISMISS_FEEDBACK:
      return {
        ...state,
        updateAnnotationsFeedback: null,
      };

    default:
      return state;
  }
}
