import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import qs from 'qs';
import { getAlpha } from 'utils/color';
import {
  selectCurrentCase,
  selectCurrentFolder,
  selectCurrentSelectedFileMetaData,
  selectIsGlobalPageLookup,
  selectIsLiveStream,
  selectMatchedUrl,
  selectPermissions,
  selectQuery,
  selectTheatreData,
  selectUserId,
  selectSessionStarted,
  selectFilteredDocumentAnnotations,
  selectMarkBasedScrolling,
  selectPresentQuery,
  selectHubConnectionId,
  selectBundleTabPage,
  selectCurrentCaseId,
  selectDocumentById,
  selectDocuments,
} from 'common/selectors';
import * as actions from '../../redux/actions';
import { ZoomType } from '../types';
import { ViewerControl, MouseTool, Mark } from '@prizmdoc/viewer-core';
import AcusoftViewerToolbox from './AcusoftViewerToolbox';
import {
  getScrollContainer,
  makeMarkDisappear,
  setScroll,
  setSearchFunction,
  setMouse,
  zoom,
  getDocumentRotationInfo,
  createAnnotations,
  setOverflowHidden,
  deriveLocalPageFromGlobalPage,
} from './utils';
import './index.scss';
import { openNewBackgroundTab } from 'utils/browser';
import { PAGENUMBER_MARK_TYPE, PRESENT_COLOR, SCROLL_MARK_TYPE } from './constants';
import { useSelector } from 'react-redux';
import { useSendMessages } from './hooks/useSendMessages';
import { useScrollToHighlight } from './hooks/useScrollToHighlight';
import { usePauseDocument } from './hooks/usePauseDocument';
import { usePerfectScrollbarUpdater } from './hooks/usePerfectScrollbarUpdater';
import { useFetchAnnotations } from 'features/viewing/redux/fetchAnnotations';
import NotionInterface from './NotionInterface';
import { useClearAnnotations } from 'features/viewing/redux/clearAnnotations';
import { useMarks } from './hooks/useMarks';
import debounce from 'utils/debounce';
import { diff } from 'deep-object-diff';
import { isEmpty } from 'utils/objects';
import { useDispatch } from 'react-redux';
import logger from 'utils/logger';
import { makeCancelable } from 'utils/promises';
import Split from 'react-split';
import classNames from 'classnames';
import Logger from 'utils/logger';
import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import SearchAllFiles from './SearchAllFiles';
import T from 'i18n';
import { Badge } from '@mui/material';

const AcusoftViewerContainer = ({
  removeInput,
  enableScroll,
  className,
  options,
  viewerReadyHandler,
  pageOpeningHandler,
  pageDisplayedHandler,
  pageListElementRef,
  viewerControlRef,
}: any) => {
  return (
    <div
      // ACUSOFT CONTAINER
      style={{
        ...(removeInput && !enableScroll && { pointerEvents: 'none' }),
      }}
      data-pcc-pagelist
      className={className}
      ref={(el) => {
        if (el !== null && !pageListElementRef.current) {
          pageListElementRef.current = el;

          viewerControlRef.current = new ViewerControl(el, options);
          viewerControlRef.current.on('ViewerReady', viewerReadyHandler);
          viewerControlRef.current.on('PageOpening', pageOpeningHandler);
          viewerControlRef.current.on('PageDisplayed', pageDisplayedHandler);
        }
      }}
    />
  );
};

export default memo(
  forwardRef(
    (
      {
        fileId,
        removeInput,
        fileData,
        className,
        enableScroll,
        isFullScreen,
        isPreviewMode,
        options,
        viewingFilePrivate,
        passViewingSessionIdToReceivers,
        firstParentTriaBundleFileDetails,
        hearingRoomMode,
        searchHandler,
        searchFunctionsHandler,
        sideSummaryOpen,
        fetchViewingSessionIdHandler,
        isQueryParamMatchingAnyFolder,
        startPageForGlobalPaging,
      }: any,
      ref,
    ) => {
      const fetchTheatreDataPending = useSelector(
        (state: any) => state.viewing.fetchTheatreDataPending,
      );
      const presentQuery = useSelector(selectPresentQuery);
      const annotationId = useSelector((state: any) => state.matcher.params.annotationId);
      const prevAnnotationIdRef = useRef(null);
      const connectionId = useSelector(selectHubConnectionId);
      const userId = useSelector(selectUserId);
      const permissions = useSelector(selectPermissions);
      const currentCase = useSelector(selectCurrentCase);
      const filteredAnnotations = useSelector(selectFilteredDocumentAnnotations);
      const isLiveStream = useSelector(selectIsLiveStream);
      const currentFolder = useSelector(selectCurrentFolder);
      const currentFileMetaData = useSelector(selectCurrentSelectedFileMetaData) as any;
      const hearingRoomStarted = useSelector(selectSessionStarted);
      const prevHearingRoomStartedRef = useRef(null);
      const isGlobalPageLookup = useSelector(selectIsGlobalPageLookup);
      const queryParam = useSelector(selectQuery);
      const currentUrl = useSelector(selectMatchedUrl);
      const btpActive = useSelector(selectBundleTabPage);
      const caseId = useSelector(selectCurrentCaseId);
      const theatreData = useSelector((state: any) =>
        selectTheatreData(state, hearingRoomMode),
      ) as any;
      const markBasedScrolling = useSelector(selectMarkBasedScrolling);

      const [docsToDisplay, setDocsToDisplay] = useState(() => {
        try {
          const storedDocs = sessionStorage.getItem(`${caseId}.docsToDisplay`);
          return storedDocs ? JSON.parse(storedDocs) : [];
        } catch (error) {
          console.error(error);
          return [];
        }
      });
      const [recentSearches, setRecentSearches] = useState<any>(() => {
        try {
          const storedSearches = sessionStorage.getItem(`${caseId}.recentSearches`);
          return storedSearches ? JSON.parse(storedSearches) : {};
        } catch (error) {
          console.error(error);
          return {};
        }
      });
      const [isViewerReady, setIsViewerReady] = useState<false | undefined | true | null>(
        undefined,
      );
      const [selectedMouseTool, setSelectedMouseTool] = useState(MouseTool.Type.PanAndEdit);
      const [value, setValue] = useState(
        () => sessionStorage.getItem(`${caseId}.searchValue`) || '',
      );
      const [chipPageNumber, setChipPageNumber] = useState<string | null>(null);
      const [marksCreatedFirstTime, setMarksCreatedFirstTime] = useState(false);
      function handleOpenDoc(doc: { id: string; name: string; globalPagePrefix: string }) {
        let pageNum = 1;
        let lastPart = '';
        if (doc.id === currentFileMetaData?.id) {
          startStopPresentHandlerWPresentView('public', true);
          if (value.includes('/')) {
            const parts = value.split('/');
            lastPart = parts[parts.length - 1];
            const parsed = parseInt(lastPart, 10);
            if (!isNaN(parsed)) {
              pageNum = parsed;
            }
          }
          if (value.includes(doc.globalPagePrefix) && btpActive === false) {
            pageNum = deriveLocalPageFromGlobalPage(value, doc);
          }
          dispatch(actions.setGoToPageNumber(pageNum));
        } else {
          switchDocument(doc);
          if (value.includes('/')) {
            const parts = value.split('/');
            lastPart = parts[parts.length - 1];
            const parsed = parseInt(lastPart, 10);
            if (!isNaN(parsed)) {
              pageNum = parsed;
            }
          }
          if (value.includes(doc.globalPagePrefix) && btpActive === false) {
            pageNum = deriveLocalPageFromGlobalPage(value, doc);
          }

          dispatch(actions.setGoToPageNumber(pageNum));
        }

        const docWithPage = {
          ...doc,
          searchPage: pageNum,
          timestamp: Date.now(),
          name: currentFileMetaData.name || doc.name,
        };
        const updatedRecentSearches = { ...recentSearches };
        const docKey = `${doc.id}_${pageNum}`;

        updatedRecentSearches[docKey] = docWithPage;
        setRecentSearches(updatedRecentSearches);
        sessionStorage.setItem(`${caseId}.recentSearches`, JSON.stringify(updatedRecentSearches));
      }

      function handleSwitchDoc(doc: {
        id: string;
        name: string;
        globalPagePrefix: string;
        searchPage?: number; // Add this optional field
      }) {
        // Use searchPage if it exists, else fall back to 1
        let pageNum = doc.searchPage ?? 1;

        if (doc.id === currentFileMetaData?.id) {
          startStopPresentHandlerWPresentView('public', true);

          // If doc.searchPage was not set for some reason, parse 'value' as a fallback
          if (!doc.searchPage && value.includes('/')) {
            const parts = value.split('/');
            const lastPart = parts[parts.length - 1];
            const parsed = parseInt(lastPart, 10);
            if (!isNaN(parsed)) {
              pageNum = parsed;
            }
          }

          if (value.includes(doc.globalPagePrefix) && btpActive === false) {
            pageNum = deriveLocalPageFromGlobalPage(value, doc);
          }

          dispatch(actions.setGoToPageNumber(pageNum));
          return;
        }

        switchDocument(doc);

        if (!doc.searchPage && value.includes('/')) {
          const parts = value.split('/');
          const lastPart = parts[parts.length - 1];
          const parsed = parseInt(lastPart, 10);
          if (!isNaN(parsed)) {
            pageNum = parsed;
          }
        }

        if (value.includes(doc.globalPagePrefix) && btpActive === false) {
          pageNum = deriveLocalPageFromGlobalPage(value, doc);
        }

        dispatch(actions.setGoToPageNumber(pageNum));
        setValue('');
      }

      function handleRemoveRecentSearch(e: React.MouseEvent, searchValue: string) {
        e.stopPropagation(); // Prevent clicking on the 'x' from also triggering handleSwitchDoc
        const updatedSearches = { ...recentSearches };
        delete updatedSearches[searchValue];
        setRecentSearches(updatedSearches);
        sessionStorage.setItem(`${caseId}.recentSearches`, JSON.stringify(updatedSearches));
      }

      const presenterConnectionId = useMemo(() => {
        return (
          (fileId &&
            (theatreData?.messages || []).find(
              (message: any) => message.fileId === fileId && userId === message.presenterId,
            )?.connectionId) ||
          null
        );
      }, [fileId, theatreData?.messages, userId]);
      const hasBeenPresenting = useRef(false);
      const showGlobalPaging = currentFolder && currentFolder.globalPaging;
      const isPresenting = !!presentQuery;
      const allHighlights = useMemo(
        () => (isPreviewMode || isPresenting ? [] : filteredAnnotations),
        [isPreviewMode, isPresenting, filteredAnnotations],
      );
      const prevHightlightsRef = useRef<any>([]);
      const isPresentModePage = currentUrl.includes(`${currentCase.id}/present-`);
      const isTheatreMode = !!hearingRoomMode;
      const showSidebar = !isTheatreMode && isFullScreen && !isPreviewMode;
      const [searchError, setSearchError] = useState('');

      const [, updateState] = React.useState<any>();

      const forceUpdate = React.useCallback(() => updateState({}), []);

      const [hasClickedSummary, setHasClickedSummary] = useState(false);
      const pageListElementRef = useRef<any>(null);
      const viewerControlRef = useRef<any>();
      const initializingRef = useRef<number>(0);
      const hideTimeoutRef = useRef<any>(null);
      const prevDoubleTimestampRef = useRef<number>(0);
      const prevTrippleTimestampRef = useRef<number>(0);
      const toolboxRef = useRef<any>(null);

      const pauseCreateMarkHandlerRef = useRef<any>(null);
      const pauseCreateMarkHandlerRef2 = useRef<any>(null);

      const { fetchAnnotations } = useFetchAnnotations();
      const { clearAnnotations } = useClearAnnotations();

      const updatePerfectScrollbar = usePerfectScrollbarUpdater(viewerControlRef);

      const { unpauseTheatreModeHandler, disconnectPauseTheatreModeHandler, pauseTheatreMode } =
        usePauseDocument(viewerControlRef, isTheatreMode, initializingRef, selectedMouseTool);

      const {
        deleteMarkToBeCreated,
        setMarkToBeUpdated,
        markToBeCreated,
        markToBeUpdated,
        setMarkToBeCreated,
      } = useMarks(
        viewerControlRef.current,
        isPresenting,
        isTheatreMode,
        pauseCreateMarkHandlerRef,
        pauseCreateMarkHandlerRef2,
      );

      const setCurrentMouseTool = useCallback(
        (selMouseTool, isPresent?) => {
          deleteMarkToBeCreated();
          if (viewerControlRef.current)
            setMouse(
              selMouseTool,
              isPresent !== undefined ? isPresent : isPresenting,
              viewerControlRef.current,
            );
        },
        [deleteMarkToBeCreated, isPresenting],
      );

      const {
        startStopPresentHandlerWPresentView,
        updateScrollPositionForReceivers,
        sendFullMessage,
        switchDocument,
      } = useSendMessages({
        passViewingSessionIdToReceivers,
        firstParentTriaBundleFileDetails,
        fileId,
        options,
        viewingFilePrivate,
        viewerControlRef: viewerControlRef,
        setCurrentMouseTool,
        selectedMouseTool,
        deleteMarkToBeCreated,
      });

      const { scrollToHighlight } = useScrollToHighlight({
        viewerControl: viewerControlRef.current,
      });

      const viewerControlMouseDownHandler = useCallback(() => {
        deleteMarkToBeCreated();
      }, [deleteMarkToBeCreated]);

      const viewerControlClickedHandler = useCallback(
        (e: any) => {
          if (e.originalEvent.timeStamp - prevTrippleTimestampRef.current < 250) {
            prevDoubleTimestampRef.current = 0;
            prevTrippleTimestampRef.current = 0;
          } else if (e.originalEvent.timeStamp - prevDoubleTimestampRef.current < 250) {
            prevDoubleTimestampRef.current = 0;
            prevTrippleTimestampRef.current = e.originalEvent.timeStamp;
          } else {
            prevDoubleTimestampRef.current = e.originalEvent.timeStamp;
          }

          if (e.mark && viewerControlRef.current.getAllMarks().includes(e.mark)) {
            if (e.originalEvent.button !== 2) {
              if (e.mark.uid === markToBeCreated?.uid) return;
              if (e.mark.type === Mark.Type.TextHyperlinkAnnotation) {
                openNewBackgroundTab(e.mark.href);
              } else {
                setMarkToBeUpdated(e.mark);
              }
            }
          }
        },
        [markToBeCreated?.uid, setMarkToBeUpdated],
      );

      const markSelectionChangedHandler = useCallback(() => {
        const allSelectedHighlights = viewerControlRef.current.getSelectedMarks();

        if (allSelectedHighlights.length > 1) {
          viewerControlRef.current.deselectMarks([allSelectedHighlights[0]]);
        } else if (allSelectedHighlights.length === 0) {
          setMarkToBeUpdated(undefined);
        } else {
          setMarkToBeUpdated(allSelectedHighlights[0]);
        }
      }, [setMarkToBeUpdated]);

      const setAllTheatreViewerData = useCallback(() => {
        const { scroll, scaleFactor, documentRotation, markObjects = [] } = fileData;

        const rotate = documentRotation - viewerControlRef.current.getPageRotation();

        const decreseIntitializing = () => {
          setTimeout(() => {
            --initializingRef.current;
            if (initializingRef.current === 0) {
              forceUpdate();
            }
          }, 500);
        };

        const setScaleFactor = () => {
          if (viewerControlRef.current.getScaleFactor() !== scaleFactor) {
            viewerControlRef.current.setScaleFactor(
              Math.max(
                Math.min(viewerControlRef.current.getMaxScaleFactor(), scaleFactor),
                viewerControlRef.current.getMinScaleFactor(),
              ),
            );
          }
          decreseIntitializing();
        };

        const setRotation = () => {
          if (rotate) {
            const onRotation = () => {
              decreseIntitializing();
              viewerControlRef.current.off('DocumentRotated', onRotation);
            };
            viewerControlRef.current.on('DocumentRotated', onRotation);
            viewerControlRef.current.rotateDocument(rotate);
          } else {
            decreseIntitializing();
          }
        };

        const _setScroll = () => {
          if (rotate) {
            if (!markBasedScrolling) {
              const centerElementX = getScrollContainer(viewerControlRef.current).clientWidth / 2;
              const centerElementY = getScrollContainer(viewerControlRef.current).clientHeight / 2;
              getScrollContainer(viewerControlRef.current).scrollTo(
                scroll.X * getScrollContainer(viewerControlRef.current).scrollWidth -
                  centerElementX,
                scroll.Y * getScrollContainer(viewerControlRef.current).scrollHeight -
                  centerElementY,
              );
            }

            decreseIntitializing();
          } else {
            if (!markBasedScrolling) {
              setScroll(getScrollContainer(viewerControlRef.current), scroll.X, scroll.Y).then(
                () => {
                  decreseIntitializing();
                },
              );
            } else {
              decreseIntitializing();
            }
          }
        };

        const applyHighlights = () => {
          try {
            const existingMarks = viewerControlRef.current.getAllMarks();

            const markObjectsToDeleteUids = markObjects
              .filter(
                ({ uid, ...rest }: any) =>
                  Object.keys(rest).length === 0 || rest?.data?.visible === 'false',
              )
              .map((x: any) => x.uid);
            const markObjectsToDelete = existingMarks.filter(({ uid }: any) =>
              markObjectsToDeleteUids.includes(uid),
            );
            // delete some marks
            if (markObjectsToDelete && markObjectsToDelete.length > 0) {
              viewerControlRef.current.deleteMarks(markObjectsToDelete);
            }

            const existingMarksUids = existingMarks.map((x: any) => x.uid);
            const newMarksObjects = markObjects.filter(
              ({ uid, ...rest }: any) =>
                Object.keys(rest).length > 0 && !existingMarksUids.includes(uid),
            );

            // create some new marks
            const newMarks =
              newMarksObjects.length > 0 &&
              viewerControlRef.current.deserializeMarks(newMarksObjects);

            const onlyPresentMarks = newMarks
              ? newMarks.filter(
                  (mark: any) =>
                    mark.type !== SCROLL_MARK_TYPE && mark.type !== PAGENUMBER_MARK_TYPE,
                )
              : [];
            const scrollMarks = newMarks
              ? newMarks.filter((mark: any) => mark.type === SCROLL_MARK_TYPE)
              : [];

            // last one needs not to disappear
            if (onlyPresentMarks.length > 0) {
              if (existingMarks) {
                existingMarks.forEach((mark: any) => {
                  if (
                    mark.fillColor === PRESENT_COLOR &&
                    mark.interactionMode === Mark.InteractionMode.SelectionDisabled &&
                    ((mark.opacity && mark.opacity === 150) ||
                      (typeof mark.opacity === 'undefined' && getAlpha(mark.fillColor) === 255))
                  ) {
                    makeMarkDisappear(mark);
                  }
                });
              }

              onlyPresentMarks
                .slice(0, -1)
                .map(
                  (mark: any) =>
                    mark.interactionMode === Mark.InteractionMode.SelectionDisabled &&
                    makeMarkDisappear(mark),
                );
            }

            if (scrollMarks.length > 0) {
              new Promise<void>((r) => {
                if (scrollMarks.length > 0) {
                  try {
                    const scrollMark = scrollMarks[scrollMarks.length - 1];

                    const scrollContainer = getScrollContainer(viewerControlRef.current);

                    viewerControlRef.current
                      .requestPageAttributes(scrollMark.pageNumber)
                      .then((attributes: any) => {
                        const pageWidth = attributes.width;

                        viewerControlRef.current
                          .scrollToAsync({
                            pageNumber: scrollMark.pageNumber,
                            x:
                              (scrollContainer.scrollWidth - pageWidth) / 2 +
                              scrollMark.boundingRectangle.x,
                            y: scrollMark.boundingRectangle.y,
                          })
                          .then(() => {
                            viewerControlRef.current.deleteMarks([
                              scrollMarks[scrollMarks.length - 1],
                            ]);
                          });
                      });
                  } catch (e) {
                    logger.ERROR(e);
                  } finally {
                    r();
                  }
                } else {
                  r();
                }
              }).then(() => {
                decreseIntitializing();
              });
            } else {
              decreseIntitializing();
            }
          } catch (e) {
            logger.ERROR(e);
            decreseIntitializing();
          }
        };

        initializingRef.current += removeInput ? 3 : 4; //it needs to reach 0 when all is completed
        if (!removeInput) {
          setScaleFactor();
        }
        setRotation();
        _setScroll();
        applyHighlights();
      }, [fileData, forceUpdate, markBasedScrolling, removeInput]);

      const updateScroll = debounce(() => updateScrollPositionForReceivers(), 500);

      const dontUseScrollHandlerRef = useRef(false);

      const scrollHandler = useCallback(() => {
        if (dontUseScrollHandlerRef.current) return;

        updateScroll();
      }, [updateScroll]);

      const scaleChangedHandler = useCallback(
        ({ scaleFactor }: any) => {
          try {
            setTimeout(() => {
              updateScrollPositionForReceivers({
                scaleFactor,
              });
              // this handler get's removed when changing scale
              dontUseScrollHandlerRef.current = false;
            }, 0);
          } catch (e) {
            logger.ERROR(e);
          }
        },
        [updateScrollPositionForReceivers],
      );

      const documentRotatedHandler = useCallback(() => {
        setTimeout(() => {
          updateScrollPositionForReceivers(getDocumentRotationInfo(viewerControlRef.current));
          // this handler get's removed when rotating document
          dontUseScrollHandlerRef.current = false;
        }, 0);
      }, [updateScrollPositionForReceivers]);

      const [highlights, setHighlights] = useState([]);
      const filterHighlightsCancelablePromise = useRef<any>(null);

      const handlePageLoadFailed = useCallback(
        (e: any) => {
          logger.ERROR(e);
          if (e.statusCode === 403) {
            fetchViewingSessionIdHandler().then(() => {
              if (isPresenting) {
                setTimeout(sendFullMessage, 1000);
              }
            });
          }
          Logger.ERROR(e);
        },
        [fetchViewingSessionIdHandler, isPresenting, sendFullMessage],
      );

      const handlePageChangedAnnotations = debounce(
        useCallback(
          ({ pageNumber, reset }: any) => {
            filterHighlightsCancelablePromise.current?.cancel();
            filterHighlightsCancelablePromise.current = makeCancelable(
              new Promise((resolve) => {
                const highlightsToShow = Array.from(
                  new Set([
                    ...(reset ? [] : highlights),
                    ...allHighlights.filter(({ annotation }: any) => {
                      return [Math.max(pageNumber - 1, 0), pageNumber, pageNumber + 1].includes(
                        annotation.pageNumber,
                      );
                    }),
                  ]),
                ) as any;
                resolve(highlightsToShow);
              }).then((highlightsToShow: any) => {
                if (highlightsToShow.length !== highlights.length) setHighlights(highlightsToShow);
              }),
            );
            filterHighlightsCancelablePromise.current.promise.then(() => {});

            toolboxRef.current?.pageNumberChangedHandler({
              pageNumber,
            });
          },
          [allHighlights, highlights],
        ),
        250,
      );

      const handlePageChanged = useCallback(({ pageNumber }: any) => {
        toolboxRef.current?.pageNumberChangedHandler({
          pageNumber,
        });
      }, []);

      const zoomHandler = useCallback((type: string) => {
        dontUseScrollHandlerRef.current = true;
        zoom(type, viewerControlRef.current);
      }, []);

      const rotateHandler = (angle: number) => {
        dontUseScrollHandlerRef.current = true;
        viewerControlRef.current.rotateDocument(angle);
      };

      const mouseToolHandler = useCallback(
        (toolType: any) => {
          setSelectedMouseTool(toolType);
          setCurrentMouseTool(toolType);
        },
        [setCurrentMouseTool],
      );

      const setPageNumberHandler = (pageNumber: number) => {
        if (!viewerControlRef.current || !viewerControlRef.current._cs) {
          console.warn('Viewer control or its internal service (_cs) is not ready.');
          return;
        }
        const totalPageCount = viewerControlRef.current.pageCount;
        viewerControlRef.current.setPageNumber(pageNumber <= totalPageCount ? pageNumber : 1);
      };

      const createMarksInViewer = useCallback(
        (highlightsToWorkWith: any = highlights, differenceWithHighlights?: any) => {
          const currentMouseTool = selectedMouseTool;
          try {
            mouseToolHandler(MouseTool.Type.Pan);
            pauseCreateMarkHandlerRef2.current = true;

            createAnnotations(
              viewerControlRef.current,
              highlightsToWorkWith,
              userId,
              differenceWithHighlights,
            ).then((newMarks: any) => {
              if (markToBeUpdated) {
                setMarkToBeUpdated(newMarks.find(({ uid }: any) => uid === markToBeUpdated.uid));
              }
            });
          } catch (e) {
            logger.ERROR(e);
          } finally {
            setTimeout(() => {
              setMarksCreatedFirstTime(true);
              mouseToolHandler(currentMouseTool);
              pauseCreateMarkHandlerRef2.current = false;
            }, 500); // needed for acusoft to stop triggering events
          }
        },
        [
          highlights,
          markToBeUpdated,
          mouseToolHandler,
          selectedMouseTool,
          setMarkToBeUpdated,
          userId,
        ],
      );

      const setReadyWithTimeout = useCallback(
        () =>
          setTimeout(() => {
            setIsViewerReady((prevState: any) => {
              let newState;
              // 4 stage to make it ready
              if (typeof prevState === 'undefined') {
                newState = null;
              } else if (prevState === null) {
                newState = false;
              } else {
                newState = true;
              }
              if (newState) {
                if (presentQuery) {
                  //start presenting if url is present and it's not presenting
                  startStopPresentHandlerWPresentView(presentQuery, true);
                }

                setSearchFunction(viewerControlRef.current, searchHandler, searchFunctionsHandler);
              }

              return newState;
            });
          }, 0),
        [presentQuery, searchFunctionsHandler, searchHandler, startStopPresentHandlerWPresentView],
      );

      const pageOpeningHandler = () => {
        if (!isTheatreMode) {
          // make document go out a bit, no default zoom
          scaleChangedHandler(ZoomType.Out);
        }

        viewerControlRef.current?.off('PageOpening', pageOpeningHandler);
      };
      const pageDisplayedHandler = () => {
        setReadyWithTimeout();
        viewerControlRef.current?.off('PageDisplayed', pageDisplayedHandler);
      };

      const viewerReadyHandler = () => {
        setReadyWithTimeout();
        setOverflowHidden();

        viewerControlRef.current.off('ViewerReady', viewerReadyHandler);
      };

      const pageCountReadyHandler = useCallback(() => {
        updatePerfectScrollbar();
        setTimeout(() => {
          try {
            handlePageChanged({ pageNumber: viewerControlRef.current.pageNumber });
          } catch {
            // TO-DO HIDING ERROR due to timeout set
          }
        }, 350);

        let count = 0;
        const countAllOfThem = () => {
          ++count;
          if (count === highlights.length || isTheatreMode) {
            viewerControlRef.current.off('MarkCreated', countAllOfThem);
            setTimeout(() => {
              setReadyWithTimeout();
            });
          }
        };
        viewerControlRef.current.on('MarkCreated', countAllOfThem);
        if (highlights.length > 0) {
          prevHightlightsRef.current = highlights;
          createMarksInViewer();
        } else {
          --count;
          countAllOfThem();
        }
      }, [
        createMarksInViewer,
        handlePageChanged,
        highlights,
        isTheatreMode,
        setReadyWithTimeout,
        updatePerfectScrollbar,
      ]);

      const dispatch = useDispatch();

      useImperativeHandle(ref, () => ({
        stopPresent: () => {
          if (presentQuery) {
            startStopPresentHandlerWPresentView();
          }
        },
      }));

      const shouldShowGlobalPaging =
        showGlobalPaging ||
        (fileData && fileData.showGlobalPaging) ||
        Object.keys(queryParam).includes('startPage') ||
        isGlobalPageLookup ||
        (firstParentTriaBundleFileDetails &&
          Object.keys(firstParentTriaBundleFileDetails).length > 0) ||
        (isPresentModePage &&
          currentFileMetaData &&
          Object.keys(currentFileMetaData).includes('folderId')) ||
        isQueryParamMatchingAnyFolder;

      const goToFirstPage = () => viewerControlRef.current.changeToFirstPage();
      const goToLastPage = () => viewerControlRef.current.changeToLastPage();
      const goToPrevPage = () => viewerControlRef.current.changeToPrevPage();
      const goToNextPage = () => viewerControlRef.current.changeToNextPage();

      const currentFileMetaDataValue = useMemo(() => {
        // Helper function to check if an object has properties
        const hasProperties = (obj: object) => obj && Object.keys(obj).length > 0;

        // Helper function to check if query params include specific keys
        const queryParamsMissing = (...keys: string[]) =>
          keys.some((key) => !Object.keys(queryParam).includes(key));

        // Helper function to merge metadata with bundle details if available
        const mergeWithBundleDetails = (metadata: any) => {
          if (hasProperties(firstParentTriaBundleFileDetails)) {
            return { ...metadata, ...firstParentTriaBundleFileDetails };
          }
          return { ...metadata };
        };

        // Case 1: Current file metadata is available and some query params are missing
        if (hasProperties(currentFileMetaData) && queryParamsMissing('tab', 'globalPagePrefix')) {
          return mergeWithBundleDetails(currentFileMetaData);
        }

        // Case 2: Use file data if available
        if (fileData) {
          return fileData.currentFileDetails;
        }

        // Case 3: When query params don't match any folder, return merged metadata
        if (!isQueryParamMatchingAnyFolder) {
          return mergeWithBundleDetails(currentFileMetaData || {});
        }

        // Case 4: Use query parameters directly when startPage is present, with globalPagePrefix converted to uppercase
        if (Object.keys(queryParam).includes('startPage')) {
          const processedQueryParams = { ...queryParam };

          // Convert globalPagePrefix to uppercase if it exists
          if (processedQueryParams.globalPagePrefix) {
            processedQueryParams.globalPagePrefix =
              processedQueryParams.globalPagePrefix.toUpperCase();
          }

          return { id: fileId, ...processedQueryParams };
        }

        // Case 5: No valid metadata source
        return null;
      }, [
        currentFileMetaData,
        queryParam,
        fileData,
        isQueryParamMatchingAnyFolder,
        firstParentTriaBundleFileDetails,
        fileId,
      ]);

      const presentModeSearchProps = {
        documents: [],
        isAllDocsWithLongList: false,
        isClickEnterToPresent: false,
        isDocFinderPage: false,
        isPresentModePage: true,
        isPresentModeWithBackEnd: false,
        isSearchModePage: false,
        isReady: true,
        placeholder: 'Search',
        showDeepSearch: true,
        selectedColumns: ['index', 'id', 'name', 'info'],
        useOnlyIdColForPresentMode: false,
      };

      useEffect(() => {
        try {
          if (!isTheatreMode)
            handlePageChangedAnnotations({
              pageNumber: viewerControlRef.current?.pageNumber,
              reset: true,
            });
        } catch (e) {
          logger.ERROR(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [allHighlights]); // don't want highlights here, I've just set it to [] to trigger the effect

      useEffect(() => {
        hasBeenPresenting.current = hasBeenPresenting.current || isPresenting;
      }, [isPresenting]);

      // mount
      useEffect(() => {
        if (!isPreviewMode) {
          fetchAnnotations({
            file: fileId,
            hearingRoomMode,
          });
        }

        const vr = viewerControlRef.current;

        // Unmount
        return () => {
          const newUrlQuery =
            qs.parse(window.location.search && window.location.search.substr(1)) || {};

          if (hasBeenPresenting.current && !newUrlQuery.present) {
            dispatch(actions.leaveAGroup('present'));
          }
          clearAnnotations();
          vr?.destroy();
        };
        // MOUNT
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        viewerControlRef.current.on('PageCountReady', pageCountReadyHandler);
        const vr = viewerControlRef.current;

        return () => {
          vr.off('PageCountReady', pageCountReadyHandler);
        };
      }, [pageCountReadyHandler]);

      useEffect(() => {
        if (!currentFileMetaDataValue || !currentFileMetaDataValue.id) return;
        // If we already have at least one entry, skip
        if (Object.keys(recentSearches).length > 0) {
          return;
        }

        // Create the doc object
        const docWithPage = {
          ...currentFileMetaDataValue,
          searchPage: currentFileMetaDataValue.searchPage ?? 1,
          timestamp: Date.now(),
          name: currentFileMetaData.name,
        };

        // Add it by doc.id, or some unique key
        const initialDocKey = currentFileMetaDataValue.id;

        // If it’s not already there, add it
        if (!recentSearches[initialDocKey]) {
          const updatedSearches = { ...recentSearches, [initialDocKey]: docWithPage };
          setRecentSearches(updatedSearches);
          sessionStorage.setItem(`${caseId}.recentSearches`, JSON.stringify(updatedSearches));
        }
      }, [currentFileMetaDataValue, recentSearches, caseId]);

      useEffect(() => {
        if (!isViewerReady) return;

        const clearMarkToBeUpdated = () => {
          setMarkToBeUpdated(undefined);
        };

        // annotations and highlights
        //added to check vivercontrol is cicked or not
        viewerControlRef.current.on('MarkSelectionChanged', markSelectionChangedHandler);
        viewerControlRef.current.on('Click', viewerControlClickedHandler);
        viewerControlRef.current.on('MouseDown', viewerControlMouseDownHandler);
        getScrollContainer(viewerControlRef.current)?.addEventListener(
          'mousedown',
          deleteMarkToBeCreated as unknown as any,
        );
        getScrollContainer(viewerControlRef.current)?.addEventListener(
          'mousedown',
          clearMarkToBeUpdated,
        );
        if (!isTheatreMode) {
          // document
          getScrollContainer(viewerControlRef.current)?.addEventListener('scroll', scrollHandler);
          viewerControlRef.current.on('ScaleChanged', scaleChangedHandler);
          viewerControlRef.current.on('DocumentRotated', documentRotatedHandler);
          viewerControlRef.current.on('PageChanged', handlePageChangedAnnotations);
        }
        // when page or scale changes we need to update customToolbox
        viewerControlRef.current.on('PageChanged', handlePageChanged);
        viewerControlRef.current.on('PageLoadFailed', handlePageLoadFailed);

        const vr = viewerControlRef.current;

        return () => {
          vr?.off('MarkSelectionChanged', markSelectionChangedHandler);
          vr?.off('Click', viewerControlClickedHandler);
          vr?.off('MouseDown', viewerControlMouseDownHandler);
          getScrollContainer(vr)?.removeEventListener(
            'mousedown',
            deleteMarkToBeCreated as unknown as any,
          );
          getScrollContainer(vr)?.removeEventListener('mousedown', clearMarkToBeUpdated);
          if (!isTheatreMode) {
            // document
            getScrollContainer(vr)?.removeEventListener('scroll', scrollHandler);
            vr?.off('ScaleChanged', scaleChangedHandler);
            vr?.off('DocumentRotated', documentRotatedHandler);
            vr?.off('PageChanged', handlePageChangedAnnotations);
          }
          // when page or scale changes we need to update customToolbox
          vr?.off('PageChanged', handlePageChanged);
          vr?.off('PageLoadFailed', handlePageLoadFailed);
        };
      }, [
        deleteMarkToBeCreated,
        documentRotatedHandler,
        handlePageChanged,
        handlePageChangedAnnotations,
        handlePageLoadFailed,
        isTheatreMode,
        isViewerReady,
        markSelectionChangedHandler,
        scaleChangedHandler,
        scrollHandler,
        setMarkToBeUpdated,
        viewerControlClickedHandler,
        viewerControlMouseDownHandler,
      ]);

      useEffect(() => {
        if (
          isViewerReady &&
          isTheatreMode &&
          fileData &&
          !pauseTheatreMode &&
          !initializingRef.current
        ) {
          setAllTheatreViewerData();
        }
      }, [fileData, isTheatreMode, isViewerReady, pauseTheatreMode, setAllTheatreViewerData]);

      useEffect(() => {
        if (isViewerReady && !isTheatreMode) {
          if (
            annotationId &&
            prevAnnotationIdRef.current !== annotationId &&
            allHighlights?.length > 0
          ) {
            prevAnnotationIdRef.current = annotationId;
            const annotation = allHighlights.find(
              (highlight: any) => highlight.id === annotationId,
            );

            if (annotation) {
              scrollToHighlight(annotation);
            }
          }
        }
      }, [
        allHighlights,
        isTheatreMode,
        isViewerReady,
        scrollToHighlight,
        annotationId,
        marksCreatedFirstTime,
      ]);

      useEffect(() => {
        if (!isViewerReady) return;

        const highlightIds = highlights.map(({ id }: any) => id);
        const highlightsDiffMap = [] as any;
        const highlightsNewMap = [] as any;

        prevHightlightsRef.current.forEach((oldHighlight: any, index: any) => {
          const idIndex = highlightIds.indexOf(oldHighlight.id);
          if (idIndex > -1) {
            highlightIds[idIndex] = null;
            highlightsDiffMap[index] = highlights[idIndex];
            highlightsNewMap[index] = highlights[idIndex];
          } else {
            highlightsDiffMap[index] = undefined;
            highlightsNewMap[index] = oldHighlight;
          }
        });

        highlightIds.filter(Boolean).forEach((id: any) => {
          const idIndex = highlightIds.indexOf(id);
          if (idIndex > -1) {
            highlightsDiffMap[highlightsDiffMap.length] = highlights[idIndex];
            highlightsNewMap[highlightsNewMap.length] = highlights[idIndex];
          }
        });

        const difference = diff(prevHightlightsRef.current, highlightsDiffMap);

        if (!isEmpty(difference)) {
          createMarksInViewer(highlightsNewMap, difference);
          prevHightlightsRef.current = highlights;
        }
      }, [createMarksInViewer, highlights, isViewerReady]);

      useEffect(() => {
        sessionStorage.setItem(`${caseId}.searchValue`, value);
        sessionStorage.setItem(`${caseId}.docsToDisplay`, JSON.stringify(docsToDisplay));
      }, [value, docsToDisplay, caseId]);

      useEffect(() => {
        if (
          presentQuery === 'public' &&
          prevHearingRoomStartedRef.current !== hearingRoomStarted &&
          !hearingRoomStarted
        ) {
          if (
            ['paused', 'started'].includes(
              prevHearingRoomStartedRef.current as unknown as string,
            ) &&
            hearingRoomStarted === 'stopped'
          ) {
            startStopPresentHandlerWPresentView();
          }
          prevHearingRoomStartedRef.current = hearingRoomStarted;
        }
      }, [
        connectionId,
        fetchTheatreDataPending,
        hearingRoomStarted,
        isViewerReady,
        startStopPresentHandlerWPresentView,
        presenterConnectionId,
        presentQuery,
      ]);

      useEffect(() => {
        if (isViewerReady && !isTheatreMode && !hasClickedSummary) {
          zoomHandler(ZoomType.Page);
          setHasClickedSummary(true);
        }
      }, [hasClickedSummary, isTheatreMode, isViewerReady, sideSummaryOpen, zoomHandler]);

      useEffect(() => {
        // Global page pattern: one or more letters,
        // an optional dot, and then a number (which might have a decimal part)
        const globalPageRegex = /^([a-zA-Z]+)\.?(\d+(?:\.\d+)?)$/;
        const globalMatch = globalPageRegex.exec(value);
        if (globalMatch) {
          // globalMatch[2] is the numeric part of the global page.
          setChipPageNumber(value);
          return;
        }

        // If the value includes a slash, take the part after the last slash.
        if (value && value.includes('/')) {
          const parts = value.split('/');
          const lastPart = parts[parts.length - 1];
          if (!isNaN(parseInt(lastPart, 10))) {
            setChipPageNumber(lastPart);
            return;
          }
        }

        // If none of the patterns match, clear the chip page number.
        setChipPageNumber(null);
      }, [value]);

      return (
        <>
          <Split
            sizes={[15, 85]}
            minSize={showSidebar ? 100 : 0}
            expandToMin={false}
            gutterSize={10}
            gutterAlign="center"
            snapOffset={30}
            dragInterval={1}
            direction="horizontal"
            cursor="col-resize"
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
            }}
            className={classNames({ 'no-gutter': !showSidebar })}
          >
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                marginTop: 5,
                paddingLeft: 20,
              }}
            >
              <SearchAllFiles
                search={presentModeSearchProps}
                setDocsToDisplay={setDocsToDisplay}
                docsToDisplay={docsToDisplay}
                handleOpenDoc={handleOpenDoc}
                hasSearch={true}
                clickToSearch={false}
                hasDocFinderFilter={true}
                isBundleTabPage={true}
                setShowDocFinderFilter={() => {}}
                showDocFinderFilter={true}
                style={{
                  display: 'flex',
                  flex: 1,
                  width: '100%',
                  padding: '0.5rem',
                }}
                presentView={true}
                value={value}
                setValue={setValue}
                setSearchError={setSearchError}
              />
              {docsToDisplay.length === 0 && value.length > 0 && (
                <div
                  style={{
                    // put this at in the middle of the screen
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '1rem',
                    minHeight: '100px',
                    textAlign: 'center',
                    color: '#666',
                  }}
                >
                  <div>
                    <p>{T.translate('case.presentView.noDocumentsFound')}</p>
                    <p style={{ fontSize: '0.9rem' }}>
                      {T.translate('case.presentView.noDocumentsFoundMsg')}
                    </p>
                  </div>

                  {searchError.length > 0 && (
                    <div style={{ color: '#d32f2f' }}>
                      <p>{searchError}</p>
                    </div>
                  )}
                </div>
              )}
              <div style={{ width: '100%', height: '100%', overflowY: 'auto', paddingBottom: 3 }}>
                {docsToDisplay.length > 0 &&
                  docsToDisplay.map((doc: any) => (
                    <div
                      style={{
                        border: '0px solid #ccc',
                        padding: '0.5rem',
                        marginBottom: '1rem',
                        borderRadius: 4,
                        backgroundColor: '#f9f9f9',
                      }}
                      key={doc.id}
                    >
                      <div>{doc.name}</div>
                      <div style={{ fontSize: '0.9rem', color: '#666' }}>{doc.id}</div>
                      <FontAwesomeIcon
                        icon={faChromecast}
                        onClick={doc.private ? undefined : () => handleOpenDoc(doc)}
                        size="2x"
                        style={{
                          cursor: doc.private ? 'not-allowed' : 'pointer',
                          marginTop: '0.5rem',
                          backgroundColor: doc.private ? '#cccccc' : '#748CEC',
                          borderRadius: 4,
                          padding: 4,
                          color: 'white',
                          opacity: doc.private ? 0.6 : 1,
                        }}
                        title={
                          doc.private
                            ? T.translate('case.presentView.tooltip.cannotPresentPrivate')
                            : T.translate('case.presentView.tooltip.presentDocument')
                        }
                      />
                      {doc && chipPageNumber && (
                        <p
                          style={{
                            height: 'auto',
                            flexFlow: 'row',
                            float: 'right',
                            marginTop: '1rem',
                          }}
                        >{`Page ${chipPageNumber}`}</p>
                      )}
                    </div>
                  ))}
                {Object.keys(recentSearches).length > 0 && (
                  <div className="recent-searches">
                    <h4 className="section-title">
                      {T.translate('case.presentView.recentlyPresented')}
                    </h4>
                    {[...Object.entries(recentSearches)]
                      .sort(
                        ([, docA]: [string, any], [, docB]: [string, any]) =>
                          (docB.timestamp || 0) - (docA.timestamp || 0),
                      )
                      .map(([searchValue, doc]) => (
                        <div className="recent-search-item" key={searchValue}>
                          <div className="search-info" onClick={() => handleSwitchDoc(doc as any)}>
                            <div className="search-id">
                              {currentFileMetaData?.id === (doc as any).id && (
                                <Badge>
                                  <FontAwesomeIcon icon={faCircle} size="xs" color="#748CEC" />
                                </Badge>
                              )}

                              {(doc as any).id}
                              {(doc as any).globalPagePrefix || (doc as any).searchPage ? (
                                <span className="page-label">{`Page ${(doc as any).globalPagePrefix || (doc as any).searchPage}`}</span>
                              ) : null}
                            </div>
                            <div className="search-name">
                              {(doc as any).name &&
                                ((doc as any).name.length > 30
                                  ? (doc as any).name.slice(0, 30) + '...'
                                  : (doc as any).name)}
                            </div>
                          </div>
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={(e) => handleRemoveRecentSearch(e, searchValue)}
                            className="remove-btn"
                          />
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            <div
              id="div22"
              style={{
                height: '100%',
                position: 'relative',
                overflow: 'auto',
              }}
            >
              {!removeInput && isViewerReady && !isLiveStream && pageListElementRef.current && (
                <AcusoftViewerToolbox
                  ref={toolboxRef}
                  viewerContainer={pageListElementRef.current}
                  isTheatreMode={isTheatreMode}
                  presenter={fileData?.presenter}
                  fileId={fileId}
                  pauseTheatreMode={pauseTheatreMode}
                  presentMode={presentQuery}
                  currentScaleFactor={viewerControlRef?.current?.getScaleFactor()}
                  pageCount={viewerControlRef.current.pageCount}
                  zoom={zoomHandler}
                  rotate={rotateHandler}
                  unpauseTheatreMode={unpauseTheatreModeHandler}
                  disconnectPauseTheatreMode={disconnectPauseTheatreModeHandler}
                  mouseTool={mouseToolHandler}
                  selectedMouseTool={selectedMouseTool}
                  goToFirstPage={goToFirstPage}
                  goToLastPage={goToLastPage}
                  goToPrevPage={goToPrevPage}
                  goToNextPage={goToNextPage}
                  setPageNumber={setPageNumberHandler}
                  present={startStopPresentHandlerWPresentView}
                  permissions={permissions}
                  privateFile={viewingFilePrivate}
                  showGlobalPaging={shouldShowGlobalPaging}
                  currentFileMetaData={currentFileMetaDataValue}
                  isPresentModePage={true}
                  isFullScreen={isFullScreen}
                  isPreviewMode={isPreviewMode}
                  startPageForGlobalPaging={startPageForGlobalPaging}
                  userId={userId}
                />
              )}
              <AcusoftViewerContainer
                removeInput={removeInput}
                enableScroll={enableScroll}
                className={className}
                options={options}
                viewerReadyHandler={viewerReadyHandler}
                pageOpeningHandler={pageOpeningHandler}
                pageDisplayedHandler={pageDisplayedHandler}
                pageListElementRef={pageListElementRef}
                viewerControlRef={viewerControlRef}
              />
              {isViewerReady && !isPresenting && (
                <NotionInterface
                  viewerControl={viewerControlRef.current}
                  isPresenting={isPresenting}
                  markToBeCreated={markToBeCreated}
                  markToBeUpdated={markToBeUpdated}
                  highlights={allHighlights}
                  isTheatreMode={isTheatreMode}
                  fileId={fileId}
                  deleteMarkToBeCreated={deleteMarkToBeCreated}
                  setMarkToBeUpdated={setMarkToBeUpdated}
                  setMarkToBeCreated={setMarkToBeCreated}
                  hideTimeoutRef={hideTimeoutRef}
                  pauseCreateMarkHandlerRef={pauseCreateMarkHandlerRef}
                  userId={userId}
                />
              )}
            </div>
          </Split>
        </>
      );
    },
  ),
);
