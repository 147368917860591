import React from 'react';
import T from 'i18n';
import { Modal } from 'features/common/MaterialBasedComponents';
import { annotationsTableAction } from '../enums';

export default ({ show, action, handleClose, handleSave, selectedRows }: any) => {
  return (
    <Modal
      show={show}
      title={T.translate(
        action === annotationsTableAction.share ? 'generic.share' : 'generic.unshare',
      )}
      handleClose={handleClose}
      handleSave={() => {
        handleSave(action, selectedRows);
        handleClose();
      }}
      saveTitle={T.translate(
        action === annotationsTableAction.share ? 'generic.share' : 'generic.unshare',
      )}
    >
      {T.translate(
        action === annotationsTableAction.share
          ? 'case.shareAnnotationsMsg'
          : 'case.unshareAnnotationsMsg',
      )}
    </Modal>
  );
};
