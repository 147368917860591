import React, { Component, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withAuth from 'features/auth/withAuth';
import NavBar from 'features/common/NavBar';
import Footer from 'features/common/Footer';
import T from 'i18n';
import history from 'common/history';
import * as actions from '../redux/actions';
import * as actionsV from 'features/viewing/redux/actions';
import { bindActionCreators } from 'redux';
import Logger from 'utils/logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import {
  selectBundleTabPage,
  selectConfidentialityRing,
  selectCurrentCaseId,
  selectCurrentUserConfidentialityRing,
} from 'common/selectors';
import { getHighlightedText } from 'utils/highlightText';
import { fetchFolderSpecificFileDetails } from '../redux/fetchFolderSpecificFileDetails';
import { fetchFileDetails } from '../redux/fetchFileDetails';
import CircularProgress from '@mui/material/CircularProgress';
import FileViewer from '../FileViewer';
import { cfRingColorMapping } from 'features/case/TableCase/TableInfo';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Folder } from '@mui/icons-material';
import { Spinner } from 'features/common';

const mapping = {
  trialbook: 'trialbundles',
  'court-bundle': 'court-bundles',
  bundle: 'bundles',
  'team-bundle': 'teamBundles',
  'private-bundle': 'privateBundles',
};

export const BundleLocations = ({
  bundleLocations,
  fileId,
  filterVal,
  filePageCount = 0,
  isCrossRef = false,
  isGrid = false,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [foundMatch, setFoundMatch] = useState(false);
  const [allBundleLocations, setAllBundleLocations] = useState([]);
  const currentCaseId = useSelector(selectCurrentCaseId);
  const isBundleTabPage = useSelector(selectBundleTabPage);
  const [folderSpecificFileDetails, setFolderSpeficFileDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const isDropDownOpen = Boolean(anchorEl);
  const locationsList = isGrid ? allBundleLocations : bundleLocations;

  const dropDownItemClicked = (path, containerType) => {
    history.push(
      history.location.pathname.replace(/\/case.*/, ``) +
        `/case/${currentCaseId}/${mapping[containerType]}/${path}#${fileId}`,
    );
  };

  const getFolderSpecificDetails = useCallback(() => {
    dispatch(fetchFolderSpecificFileDetails({ fileId, firstTrialbookOnly: false }))
      .then((res) => {
        setFolderSpeficFileDetails(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [dispatch, fileId]);

  const getFileDetails = useCallback(() => {
    setFetching(true);
    dispatch(fetchFileDetails({ fileId })).then((res) => {
      setAllBundleLocations([...res.bundleLocations]);
      setFetching(false);
      setTimeout(() => getFolderSpecificDetails(), 0);
    });
  }, [fileId, dispatch, getFolderSpecificDetails]);

  useEffect(() => {
    if (isDropDownOpen) {
      setLoading(true);
      if (isGrid) {
        getFileDetails();
      } else {
        getFolderSpecificDetails();
      }
    }
  }, [isDropDownOpen, isGrid, getFolderSpecificDetails, getFileDetails]);

  useEffect(() => {
    if (filterVal) {
      const fileNames = locationsList.map((item) => item.fileName.toLowerCase()).join(', ');
      filterVal && fileNames.includes(filterVal.toLowerCase())
        ? setFoundMatch(true)
        : setFoundMatch(false);
    }
  }, [filterVal, locationsList]);

  return (
    <>
      <IconButton
        size="small"
        disableRipple
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        sx={{ p: 0, m: 0 }}
      >
        <Folder
          color={foundMatch ? 'primary' : 'action'}
          fontSize="small"
          sx={{ p: 0, m: 0, fontSize: '1.15rem', ...(isCrossRef && { color: 'white' }) }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isDropDownOpen}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorEl(null);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      >
        <MenuItem disabled>
          {isCrossRef
            ? T.translate('viewing.crossRefLocations')
            : T.translate('viewing.fileLocations')}
        </MenuItem>
        {fetching ? (
          <MenuItem>
            <CircularProgress size={'2rem'} />
          </MenuItem>
        ) : locationsList.length > 0 ? (
          locationsList.map((item, idx) => {
            const details = folderSpecificFileDetails.find((val) => val.folderId === item.folderId);
            return (
              <MenuItem
                key={idx}
                onClick={() => dropDownItemClicked(item.path, item.containerType)}
              >
                {`${mapping[item.containerType]} > ${item.pathName} > `}
                <span
                  dangerouslySetInnerHTML={{
                    __html: getHighlightedText(item.fileName, filterVal),
                  }}
                ></span>
                {loading ? (
                  <CircularProgress size={'1rem'} sx={{ marginLeft: '1rem' }} />
                ) : details && details.globalPagePrefix ? (
                  <span style={{ whiteSpace: 'pre' }}>
                    {isBundleTabPage && details.tab ? (
                      <>{`  [${details.globalPagePrefix} / ${details.tab}]`}</>
                    ) : (
                      <>
                        {details.tab ? ` [Tab ${details.tab}]` : ''} {details.globalPagePrefix}
                        {details.startPage} -
                        {parseInt(details.startPage, 10) + parseInt(filePageCount, 10) - 1}
                      </>
                    )}
                  </span>
                ) : (
                  ''
                )}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem>{T.translate('viewing.noFileLocations')}</MenuItem>
        )}
      </Menu>
    </>
  );
};
export class Viewer extends Component {
  static propTypes = {
    fileId: PropTypes.string,
    folderId: PropTypes.string,
    startPage: PropTypes.string,
    tab: PropTypes.string,
    globalPagePrefix: PropTypes.string,
    goToPage: PropTypes.string,
    isBundleTabPage: PropTypes.bool,
    isConfidentialityRing: PropTypes.bool,
    folderSpecificFileDetails: PropTypes.array,
    fetchCasesPending: PropTypes.bool,
    isRecordingsPage: PropTypes.bool,
    actions: PropTypes.object,
    actionsV: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    title: document.title,
    searchDetails: null,
    searchFunctions: null,
    filePageCount: null,
    fileType: null,
    fileName: null,
    bundleLocations: [],
    bundleSpecificFileDetails: {},
    currentFileMetaData: null,
  };

  fetchDetails = () => {
    return this.props.actions
      .fetchFileDetails({ fileId: this.props.fileId, firstTrialBookOnly: true })
      .then((res) => {
        const { type: fileType, name: fileName, bundleLocations, pageCount: filePageCount } = res;

        const currentFileFirstTrialBundleLocation =
          bundleLocations &&
          bundleLocations.length > 0 &&
          bundleLocations.find((bundle) => bundle.containerType === 'trialbook');

        if (currentFileFirstTrialBundleLocation) {
          this.props.actions.setCurrentSelectedFile(res);

          // Just dispatch the action - don't wait for promise
          this.props.actions.fetchFolderSpecificFileDetails({
            fileId: this.props.fileId,
            firstTrialbookOnly: false,
          });
        }

        this.setState({
          fileType,
          fileName,
          bundleLocations,
          filePageCount,
          currentFileMetaData: res,
        });
      })
      .catch(({ error }) => {
        Logger.ERROR('Fetching File Details', error);
      });
  };

  componentDidMount() {
    if (this.props.goToPage) {
      this.props.actionsV.setGoToPageNumber(parseInt(this.props.goToPage));
    }

    if (this.props.fileId) this.fetchDetails();

    document.title = this.state.title;
  }

  componentDidUpdate(prevProps) {
    // Handle file ID change by fetching details
    if (prevProps.fileId !== this.props.fileId) {
      this.fetchDetails();
    }

    // Handle when folderSpecificFileDetails are updated in Redux
    if (
      prevProps.folderSpecificFileDetails !== this.props.folderSpecificFileDetails &&
      this.props.folderSpecificFileDetails &&
      this.props.folderSpecificFileDetails.length > 0
    ) {
      // Find the current bundle location
      const currentFileFirstTrialBundleLocation =
        this.state.bundleLocations &&
        this.state.bundleLocations.length > 0 &&
        this.state.bundleLocations.find((bundle) => bundle.containerType === 'trialbook');

      if (currentFileFirstTrialBundleLocation) {
        // Find the matching bundle details
        const bundleDetails = this.props.folderSpecificFileDetails.find(
          (item) => item.folderId === currentFileFirstTrialBundleLocation.folderId,
        );

        // Update state if bundle details found
        if (bundleDetails) {
          this.setState({ bundleSpecificFileDetails: bundleDetails });
        }
      }
    }
  }

  // First, add the new method to get matching folder item
  getMatchingFolderFromQueryParams = () => {
    const queryParam = Object.fromEntries(new URLSearchParams(window.location.search));
    const folderSpecificFileDetails = this.props.folderSpecificFileDetails;

    // Check if all three required parameters exist in queryParam
    const hasRequiredQueryParams = ['tab', 'globalPagePrefix', 'pageCount'].every((param) =>
      Object.keys(queryParam).includes(param),
    );

    // If any parameter is missing, return null
    if (!hasRequiredQueryParams) {
      return null;
    }

    // If folderSpecificFileDetails isn't available or is empty, return null
    if (!folderSpecificFileDetails || folderSpecificFileDetails.length === 0) {
      return null;
    }

    // Find and return the matching folder or null if no match
    return (
      folderSpecificFileDetails.find(
        (folder) =>
          folder.tab === queryParam.tab &&
          folder.globalPagePrefix.toUpperCase() === queryParam.globalPagePrefix.toUpperCase() &&
          folder.pageCount === queryParam.pageCount,
      ) || null
    );
  };

  isQueryParamMatchingAnyFolder = () => {
    return this.getMatchingFolderFromQueryParams() !== null;
  };

  getFormattedPageRange = () => {
    // Determine where to get the values from based on isQueryParamMatchingAnyFolder
    let startPage, globalPagePrefix, filePageCount;

    if (!this.isQueryParamMatchingAnyFolder()) {
      // Use values from bundleSpecificFileDetails
      startPage = this.state.bundleSpecificFileDetails.startPage || this.props.startPage;
      globalPagePrefix =
        this.state.bundleSpecificFileDetails.globalPagePrefix || this.props.globalPagePrefix;
      filePageCount = this.state.bundleSpecificFileDetails.pageCount || this.state.filePageCount;
    } else {
      // Use values from props
      startPage = this.props.startPage;
      globalPagePrefix = this.props.globalPagePrefix.toUpperCase();
      filePageCount = this.state.filePageCount;
    }

    // Return early if required values are missing
    if (!startPage || !globalPagePrefix || !filePageCount) {
      return '';
    }

    // Calculate end page based on whether startPage contains a decimal
    let endPage;

    if (startPage?.includes('.')) {
      const fileStartPage = startPage.split('.');
      const baseNumber = fileStartPage[0];
      let decimalPart = fileStartPage[1];

      const isStartingWithZero = decimalPart.startsWith('0');

      // Handle decimal part formatting
      let decimalValue;
      if (isStartingWithZero) {
        // Remove leading zero but preserve it in the output
        decimalValue = parseInt(decimalPart.slice(1), 10);
        endPage = `${baseNumber}.0${decimalValue + parseInt(filePageCount, 10) - 1}`;
      } else {
        decimalValue = parseInt(decimalPart, 10);
        endPage = `${baseNumber}.${decimalValue + parseInt(filePageCount, 10) - 1}`;
      }
    } else {
      // Simple integer case
      endPage = parseInt(startPage, 10) + parseInt(filePageCount, 10) - 1;
    }

    return ` ${globalPagePrefix}${startPage}-${endPage}`;
  };

  getBrandText = (isSharing) => {
    const folder = this.state.bundleLocations.find(
      (bundle) => bundle.folderId === this.props.folderId,
    );

    const brandText = this.props.isRecordingsPage
      ? 'Recordings > '
      : folder
        ? `${`${mapping[folder.containerType]} > ${folder.pathName}`} > ${folder.fileName}`
        : this.state.fileName
          ? this.state.fileName
          : '';

    const getPageRange = () => {
      if (this.props.isBundleTabPage) {
        if (!this.isQueryParamMatchingAnyFolder()) {
          // Make sure bundleSpecificFileDetails exists and has globalPagePrefix
          if (
            this.state.bundleSpecificFileDetails &&
            this.state.bundleSpecificFileDetails.globalPagePrefix
          ) {
            // Add a space after the slash to match expected format "A / 5"
            return ` ${this.state.bundleSpecificFileDetails.globalPagePrefix} / `;
          }
          // If bundleSpecificFileDetails is not available, fall back to props
          if (this.props.globalPagePrefix) {
            return ` ${this.props.globalPagePrefix} / `;
          }
          return ''; // Return empty string if data isn't available yet
        }
        // Fix this line to match expected format "A / 5" by adding space after the slash
        return this.props.globalPagePrefix
          ? ` ${this.props.globalPagePrefix.toUpperCase()} / `
          : '';
      }
      // For the non-bundle case, make sure we have all needed values before calculating
      if (
        (this.props.startPage || this.props.goToPage) &&
        (this.state.filePageCount ||
          (this.state.bundleSpecificFileDetails && this.state.bundleSpecificFileDetails.pageCount))
      ) {
        return this.getFormattedPageRange();
      }

      return ''; // Return empty string if we don't have enough data yet
    };

    const getFileId = () => (this.props.fileId ? ` - (${this.props.fileId})` : '');

    const getTab = () => {
      // Always use props.tab as a fallback
      const tab = !this.isQueryParamMatchingAnyFolder()
        ? this.state.bundleSpecificFileDetails.tab || this.props.tab
        : this.props.tab;

      if (this.props.isBundleTabPage) {
        return `${tab}`;
      }
      return tab ? ` [Tab ${tab}]` : '';
    };

    if (isSharing) {
      return `${this.props.fileId} ${getTab()} ${folder?.fileName || this.state.fileName || ''}`;
    }
    return `${brandText}${getFileId()}${getPageRange()}${getTab()}`;
  };

  searchHandler = (searchDetails) => {
    this.setState({ searchDetails });
  };

  searchFunctionsHandler = (searchFunctions) => {
    this.setState({ searchFunctions });
  };
  render() {
    const folder = this.state.bundleLocations.find(
      (bundle) => bundle.folderId === this.props.folderId,
    );

    const isSharing = this.props?.history?.location?.search?.includes('view=share');
    // Get matching folder for global paging start page
    const matchingFolder = this.getMatchingFolderFromQueryParams();

    // Determine the start page for global paging
    const startPageForGlobalPaging =
      !this.props.isBundleTabPage && matchingFolder ? matchingFolder.startPage : null;
    return (
      !this.props.fetchCasesPending && (
        <div className="case-theatre">
          <NavBar
            hasSearch={!isSharing}
            search={this.state.searchDetails}
            searchFunctions={this.state.searchFunctions}
            brandText={this.getBrandText(isSharing)}
            confidentialityRingLabel={
              this.props.isConfidentialityRing &&
              this.state.currentFileMetaData &&
              this.state.currentFileMetaData.confidentialityRing ? (
                <FontAwesomeIcon
                  style={{
                    color: cfRingColorMapping[this.state.currentFileMetaData.confidentialityRing],
                    paddingTop: '0.2rem',
                  }}
                  icon={faCircle}
                />
              ) : (
                ''
              )
            }
            bundleLocations={
              !this.props.isRecordingsPage &&
              !isSharing && (
                <BundleLocations
                  bundleLocations={this.state.bundleLocations}
                  fileId={this.props.fileId}
                  filePageCount={this.state.filePageCount}
                />
              )
            }
          />
          <div style={{ height: 'calc(100% - 7rem)' }}>
            {(!this.props.fileId || this.state.currentFileMetaData) && (
              <FileViewer
                bundleSpecificFileDetails={this.state.bundleSpecificFileDetails}
                searchHandler={this.searchHandler}
                searchFunctionsHandler={this.searchFunctionsHandler}
                isFullScreen
                isQueryParamMatchingAnyFolder={this.isQueryParamMatchingAnyFolder()}
                currentFileMetaData={this.state.currentFileMetaData}
                startPageForGlobalPaging={startPageForGlobalPaging}
              />
            )}
          </div>
          <Footer />
        </div>
      )
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  const {
    home: { cases, fetchCasesPending },
    matcher: {
      params: { case: currentCase, file, url },
      query: { folderId, tab, startPage, globalPagePrefix, goToPage },
    },
    case: { folderSpecificFileDetails },
  } = state;
  const currentUserCR = selectCurrentUserConfidentialityRing(state);
  const isBundleTabPage = selectBundleTabPage(state);
  const isConfidentialityRing = selectConfidentialityRing(state) && currentUserCR !== 'noRing';
  return {
    case: cases.find((c) => c.id === currentCase) || {},
    fetchCasesPending,
    fileId: file,
    url,
    folderId,
    isRecordingsPage: folderId === 'recordings',
    tab,
    startPage,
    globalPagePrefix,
    goToPage,
    isBundleTabPage,
    isConfidentialityRing,
    folderSpecificFileDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
    actionsV: bindActionCreators({ ...actionsV }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(Viewer, { forceLogin: true }));
