import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { TextForm } from 'features/common';
import moment from 'moment';
import T from 'i18n';

const FormattedDateInput = ({
  dateTimeVal,
  setDateTimeVal,
  errors,
  register,
  trigger,
  currentSelection,
  focusDateField = false,
}: any) => {
  const [validationTimer, setValidationTimer] = useState<NodeJS.Timeout | null>(null);
  const [shouldShowError, setShouldShowError] = useState(false);

  // Check if a date is completely entered (DD/MM/YYYY format has 10 characters)
  const isDateComplete = (dateStr: string) => {
    return dateStr?.length === 10;
  };

  const isValidDate = (val: any) => {
    if (!val) return true;

    const isComplete = isDateComplete(val);

    // If the date is complete or we should show errors, validate it
    if (isComplete || shouldShowError) {
      return moment(val, 'DD/MM/YYYY', true).isValid();
    }

    // If the user is still typing and we shouldn't show errors yet, pass validation
    return true;
  };

  // Cleanup validation timer on unmount
  useEffect(() => {
    return () => {
      if (validationTimer) clearTimeout(validationTimer);
    };
  }, [validationTimer]);

  const isValidKey = (event: any) => {
    const key = window.event ? event.keyCode : event.which;
    const {
      keyCode,
      ctrlKey,
      metaKey,
      shiftKey,
      target: { value },
    } = event;

    // Prevent duplicate separators
    if (
      (!value ||
        (value.match(new RegExp('/', 'g')) || []).length === 2 ||
        value[value.length - 1] === '/') &&
      (keyCode === 111 || keyCode === 191)
    )
      event.preventDefault();
    // Allow navigation, deletion, and copy/paste
    else if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 46 ||
      keyCode === 37 ||
      keyCode === 39 ||
      keyCode === 35 ||
      keyCode === 36 ||
      keyCode === 191 ||
      keyCode === 111 ||
      ((ctrlKey || metaKey) && (keyCode === 65 || keyCode === 67 || keyCode === 86))
    )
      return true;
    // Prevent non-numeric input (except allowed keys)
    else if ((shiftKey || key < 48 || key > 57) && (key < 96 || key > 105)) event.preventDefault();
    else return true;
  };

  const handleDateTimeChange = ({ target: { value } }: any, key: any, separator: any) => {
    // Start a new typing session
    setShouldShowError(false);

    // Clear existing validation timer
    if (validationTimer) {
      clearTimeout(validationTimer);
    }

    // Set a new validation timer (show errors after 1 second of inactivity)
    const newTimer = setTimeout(() => {
      setShouldShowError(true);
      trigger('date');
    }, 1000);

    setValidationTimer(newTimer);

    // Enhanced maximum length check:
    // 1. Prevent initial input beyond 10 characters
    // 2. Prevent adding characters when already at 10 characters (complete date)
    if (key === 'date') {
      const currentLength = dateTimeVal[key as keyof typeof dateTimeVal]?.length || 0;

      if (value.length > 10) {
        // Truncate to 10 characters if somehow input exceeds max length
        const truncatedValue = value.substring(0, 10);
        setDateTimeVal({
          ...dateTimeVal,
          [key]: truncatedValue,
        });
        return;
      }

      // If the date is already complete and the user is trying to add more characters
      // (not deleting or replacing), prevent the change
      if (currentLength === 10 && value.length > 10) {
        return;
      }
    }

    if (value.length < dateTimeVal[key as keyof typeof dateTimeVal].length) {
      // Handle deletion
      setDateTimeVal({
        ...dateTimeVal,
        [key]: value,
      });

      // Check if we need to validate after deletion
      if (errors && Object.keys(errors).includes('date') && key === 'date' && value.length === 0) {
        setTimeout(() => trigger('date'), 0);
      }
    } else if (value.length === 2 || value.length === 5) {
      // Auto-add separators at appropriate positions
      if (value.length === 2 && (value[1] === '/' || value[1] === '.')) {
        setDateTimeVal({ ...dateTimeVal, [key]: '0' + value[0] + separator });
      } else if (value.length === 5 && (value[4] === '/' || value[4] === '.')) {
        setDateTimeVal({
          ...dateTimeVal,
          [key]: value.slice(0, 3) + '0' + value[3] + separator,
        });
      } else {
        setDateTimeVal({ ...dateTimeVal, [key]: value + separator });
      }

      // If we just completed a section, user is likely still typing - don't validate yet
    } else if (key === 'date' && value.length === 4 && parseInt(value[3]) > 1) {
      // Format month if needed
      setDateTimeVal({
        ...dateTimeVal,
        [key]: value.slice(0, 3) + '0' + value[3] + separator,
      });
    } else if (value.length > 2 && value[2] !== separator && !value.slice(0, 4).includes('/')) {
      // Add separator after DD if missing
      setDateTimeVal({
        ...dateTimeVal,
        [key]: value.slice(0, 2) + separator + value.slice(2),
      });
    } else if (value.length > 5 && value[5] !== separator && !value.slice(4, 7).includes('/')) {
      // Add separator after MM if missing
      setDateTimeVal({
        ...dateTimeVal,
        [key]: value.slice(0, 5) + separator + value.slice(5),
      });
    } else {
      // Normal update - enforce maximum length for date
      if (key === 'date' && value.length > 10) {
        setDateTimeVal({
          ...dateTimeVal,
          [key]: value.substring(0, 10),
        });
      } else {
        setDateTimeVal({
          ...dateTimeVal,
          [key]: value,
        });
      }

      // If date is complete, validate immediately
      if (value.length === 10) {
        if (validationTimer) {
          clearTimeout(validationTimer);
        }
        setValidationTimer(null);
        setShouldShowError(true);
        setTimeout(() => trigger('date'), 0);
      }
    }
  };

  // Handle blur to validate immediately
  const handleBlur = () => {
    if (validationTimer) {
      clearTimeout(validationTimer);
    }
    setShouldShowError(true);
    trigger('date');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextForm
          {...register('date', {
            validate: {
              isValidDate: (val: any) => isValidDate(val),
            },
            required: true,
            onChange: (e: any) => handleDateTimeChange(e, 'date', '/'),
          })}
          label={T.translate('case.dateWithFormat')}
          placeholder={T.translate('case.datePlaceholder')}
          value={dateTimeVal.date}
          onKeyDown={(e: any) => isValidKey(e)}
          onBlur={handleBlur}
          errors={shouldShowError ? errors : {}}
          autofocus={(!currentSelection && focusDateField) || currentSelection === 'date'}
        />
      </Grid>
    </Grid>
  );
};

export default FormattedDateInput;
