import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  CASE_UPDATE_ANNOTATION_SORT_BEGIN,
  CASE_UPDATE_ANNOTATION_SORT_SUCCESS,
  CASE_UPDATE_ANNOTATION_SORT_FAILURE,
  CASE_UPDATE_ANNOTATION_SORT_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import {
  withCurrentCaseId,
  selectCurrentFolder,
  selectAnnotationsIds,
} from '../../../common/selectors';
import { moveItemImmutable } from 'utils/arrays';

export function updateAnnotationSort({ index = 0, ids = [] }) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: CASE_UPDATE_ANNOTATION_SORT_BEGIN,
    payload: { index, ids },
  };
}

export function dismissUpdateAnnotationSortFeedback() {
  return {
    type: CASE_UPDATE_ANNOTATION_SORT_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPDATE_META_BEGIN actions
export function* doUpdateAnnotationSort(action) {
  const {
    payload: { index, caseId },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);
  const annotationsSortOrder = yield select(selectAnnotationsIds);

  const res = yield call(
    api.put,
    `/cases/${caseId}/tags/${currentFolder.id}/locations`,
    annotationsSortOrder,
  );

  if (res && res.error) {
    return yield put({
      type: CASE_UPDATE_ANNOTATION_SORT_FAILURE,
      feedback: {
        message: 'feedback.updateAnnotationSortFailure',
        error: res.error,
        index,
        retryAction: action,
      },
    });
  }

  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: CASE_UPDATE_ANNOTATION_SORT_SUCCESS,
    data: {
      annotations: res,
    },
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateAnnotationSort() {
  yield takeLatest(CASE_UPDATE_ANNOTATION_SORT_BEGIN, withCurrentCaseId(doUpdateAnnotationSort));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_ANNOTATION_SORT_BEGIN: {
      const ids = action.payload.ids;
      const indexOfTheFirstAnnotationToMove = state.annotations.findIndex(
        (doc) => doc.id === action.payload.ids[0],
      );
      if (indexOfTheFirstAnnotationToMove > action.payload.index) ids.reverse();
      return {
        ...state,
        updateAnnotationSortPending: true,
        updateAnnotationSortFeedback: null,
        prevSortedAnnotations: state.annotations,
        annotations: ids.reduce((annotations, id) => {
          const indexOfTheAnnotationToMove = annotations.findIndex((doc) => doc.id === id);
          return moveItemImmutable(
            annotations,
            annotations[indexOfTheAnnotationToMove],
            indexOfTheAnnotationToMove,
            action.payload.index,
          );
        }, state.annotations),
      };
    }

    case CASE_UPDATE_ANNOTATION_SORT_SUCCESS:
      return {
        ...state,
        updateAnnotationSortPending: false,
        //annotations: action.data.annotations,
        updateAnnotationSortFeedback: action.feedback,
        prevSortedAnnotations: null,
      };

    case CASE_UPDATE_ANNOTATION_SORT_FAILURE:
      return {
        ...state,
        updateAnnotationSortPending: false,
        updateAnnotationSortFeedback: action.feedback,
        annotations: state.prevSortedAnnotations,
        prevSortedAnnotations: null,
      };
    case CASE_UPDATE_ANNOTATION_SORT_DISMISS_FEEDBACK:
      return {
        ...state,
        updateAnnotationSortFeedback: null,
      };

    default:
      return state;
  }
}
