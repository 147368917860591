import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import T from 'i18n';
import { Modal } from 'features/common/MaterialBasedComponents';
import {
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Form } from 'react-bootstrap';
import { SelectForm, Spinner, TextForm } from 'features/common';
import { DocumentLookup } from 'features/viewing';
import EventsDateTimeForm from './EventsDateTimeForm';
import { useFetchTimelines } from '../redux/fetchTimelines';
import { CloseOutlined, Upload } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { createTimelines } from '../redux/createTimelines';
import { fetchContainer } from '../redux/fetchContainer';
import { useSelector } from 'react-redux';
import { selectCurrentFolderId } from 'common/selectors';

const AddNewEvent = ({
  show,
  action,
  handleClose,
  handleSave,
  showTimelineList = false,
  mark = null,
  defaultFile = null,
}: any) => {
  const dispatch = useDispatch();
  const { fetchTimelines } = useFetchTimelines();
  const currentFolderId = useSelector(selectCurrentFolderId);
  const [dateTimeVal, setDateTimeVal] = useState({ date: '', time: '' });
  const [timelineOptions, setTimelineOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showNewTimelineInp, setShowNewTimelineInp] = useState(false);
  const [newTimelineInp, setNewTimelineInp] = useState('');
  const [focusDateField, setFocusDateField] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    defaultValues: {
      date: '',
      time: '',
      name: '',
      file: defaultFile,
      fileText: mark ? mark.text : '',
      comment: '',
      timelineId: '',
    },
  });

  const handleCreateNewTimeline = () => {
    setLoading(true);
    dispatch(createTimelines({ timelines: { name: newTimelineInp } }))
      .then((res: any) => {
        setTimelineOptions([...timelineOptions, res]);
        setValue('timelineId', res.id);
        setShowNewTimelineInp(false);
        setLoading(false);
        dispatch(fetchContainer({ folder: { type: 'timelines' } }));
      })
      .finally(() => {
        setLoading(false);
        setShowNewTimelineInp(false);
      });
  };

  useEffect(() => {
    if (show && showTimelineList) {
      setLoading(true);
      fetchTimelines()
        .then((res: any) => {
          setTimelineOptions(res);
          const currentTimeline = res.find((itm: any) => itm.id === currentFolderId);
          if (res.length === 1) {
            setValue('timelineId', res[0].id);
            setFocusDateField(true);
          } else if (currentTimeline) {
            setValue('timelineId', currentTimeline.id);
            setFocusDateField(true);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [show, showTimelineList, fetchTimelines, setValue, currentFolderId]);

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleSave={handleSubmit((data) => {
        const { date, time, ...rest } = data;
        const dateTime = !!time ? `${date} ${time}` : date;
        if (showTimelineList)
          handleSave(
            timelineOptions.find((itm: any) => itm.id === data.timelineId),
            { ...rest, date: dateTime },
          );
        else handleSave(action, { ...rest, date: dateTime });
        handleClose();
      })}
      saveTitle={T.translate('generic.add')}
      title={T.translate('generic.addEvent')}
      draggableDialog
      //disableSave={showNewTimelineInp}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Form.Group>
            {showTimelineList && (
              <Grid container spacing={2} sx={{ mt: '-2rem' }}>
                <Grid item xs={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ flexGrow: 1 }}>
                      {'hiii' + T.translate('case.selectTimeline')}
                    </Typography>
                    {showNewTimelineInp ? (
                      <ClickAwayListener onClickAway={() => setShowNewTimelineInp(false)}>
                        <div style={{ marginBottom: '0.2rem' }}>
                          <TextField
                            placeholder={T.translate('case.newTimelinePlaceholder')}
                            variant="standard"
                            size="small"
                            autoFocus
                            onChange={(e: any) => setNewTimelineInp(e.target.value)}
                            onKeyDown={(e: any) => {
                              if (e.key === 'Enter') {
                                e.stopPropagation();
                                e.preventDefault();
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleCreateNewTimeline()}
                                  >
                                    <Upload fontSize="small" />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    onClick={() => setShowNewTimelineInp(false)}
                                  >
                                    <CloseOutlined fontSize="small" />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </ClickAwayListener>
                    ) : (
                      <Button variant="text" onClick={() => setShowNewTimelineInp(true)}>
                        {T.translate('case.createNewTimeline')}
                      </Button>
                    )}
                  </div>
                  <SelectForm
                    {...register('timelineId')}
                    /*@ts-ignore*/
                    label={null}
                    placeholder={T.translate('case.selectTimelinePlaceholder')}
                    defaultValue={''}
                    valueKey="id"
                    labelKey="name"
                    options={timelineOptions}
                    control={control}
                    rules={{ required: true }}
                    onChange={undefined}
                    isClearable
                    errors={errors}
                    menuPosition={undefined}
                    readOnly={undefined}
                    loadOptions={undefined}
                    autofocus={!focusDateField}
                    getOptionLabel={undefined}
                    defaultOptions={undefined}
                    isMulti={undefined}
                    customOption={undefined}
                    noIndicator={undefined}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <EventsDateTimeForm
                dateTimeVal={dateTimeVal}
                setDateTimeVal={setDateTimeVal}
                errors={errors}
                trigger={trigger}
                register={register}
                focusDateField={!showTimelineList || focusDateField}
              />
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={6}>
                <TextForm
                  {...register('name', { required: true })}
                  /*@ts-ignore*/
                  as="textarea"
                  label={T.translate('case.eventName')}
                  placeholder={T.translate('case.eventNamePlaceholder')}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="file"
                  control={control}
                  render={({ field }: any) => {
                    return (
                      <div>
                        <Form.Label>{T.translate('case.file')}</Form.Label>
                        <div style={{ border: '1px solid #E3E3E3', borderRadius: '4px' }}>
                          <DocumentLookup
                            {...field}
                            sx={{ padding: '4px 0px', flexGrow: 1 }}
                            placeholder={T.translate('case.linkPlaceholder')}
                            defaultValue={defaultFile}
                            readOnly={!!defaultFile}
                          />
                        </div>
                      </div>
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={6}>
                <TextForm
                  {...register('fileText')}
                  /*@ts-ignore*/
                  as="textarea"
                  defaultValue={mark ? mark.text : ''}
                  readOnly={!!mark}
                  label={T.translate('case.fileText')}
                  placeholder={T.translate('case.fileTextPlaceholder')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextForm
                  {...register('comment')}
                  /*@ts-ignore*/
                  as="textarea"
                  label={T.translate('case.comment')}
                  placeholder={T.translate('case.commentPlaceholder')}
                />
              </Grid>
            </Grid>
          </Form.Group>
        )}
      </Form>
    </Modal>
  );
};

export default AddNewEvent;
