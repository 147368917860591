import { put, take, cancel, fork, call } from 'redux-saga/effects';
import {
  CASE_UPDATE_ANNOTATIONS_SHARE_BEGIN,
  CASE_UPDATE_ANNOTATIONS_SHARE_SUCCESS,
  CASE_UPDATE_ANNOTATIONS_SHARE_FAILURE,
  CASE_UPDATE_ANNOTATIONS_SHARE_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId, withCurrentUserId } from 'common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const updateAnnotationsShare = createPromiseAction(CASE_UPDATE_ANNOTATIONS_SHARE_BEGIN);

export function dismissUpdateAnnotationsShareFeedback() {
  return {
    type: CASE_UPDATE_ANNOTATIONS_SHARE_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPDATE_ANNOTATIONS_SHARE_BEGIN actions
export function* doUpdateAnnotationsShare(action) {
  const { annotations, share, caseId, isTags } = action.payload;

  const res = yield call(api.put, `/cases/${caseId}/annotations`, {
    annotations: annotations.map(({ id }) => id),
    action: share ? 'share' : 'unshare',
  });

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_ANNOTATIONS_SHARE_FAILURE,
      feedback: {
        message: 'feedback.updateAnnotationsShareFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UPDATE_ANNOTATIONS_SHARE_SUCCESS,
    data: { annotations: res, isTags },
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateAnnotationsShare() {
  //debounce
  let task;
  while (true) {
    const action = yield take(updateAnnotationsShare);
    if (task && !action.noDebounce) {
      yield cancel(task);
    }
    task = yield fork(withCurrentUserId(withCurrentCaseId(doUpdateAnnotationsShare)), action);
  }
}

export function useUpdateAnnotationsShare() {
  const dispatch = useDispatch();

  const { updateAnnotationsSharePending, updateAnnotationsShareFeedback, annotations } =
    useSelector(
      (state) => ({
        updateAnnotationsSharePending: state.viewing.updateAnnotationsSharePending,
        updateAnnotationsShareFeedback: state.viewing.updateAnnotationsShareFeedback,
        annotations: state.viewing.annotations,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateAnnotationsShare(...args));
    },
    [dispatch],
  );

  return {
    annotations,
    updateAnnotationsShare: boundAction,
    updateAnnotationsSharePending,
    updateAnnotationsShareFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_ANNOTATIONS_SHARE_BEGIN:
      return {
        ...state,
        updateAnnotationsSharePending: true,
        updateAnnotationsShareFeedback: null,
      };

    case CASE_UPDATE_ANNOTATIONS_SHARE_SUCCESS: {
      const { annotations, isTags } = action.data;

      if (isTags) {
        return {
          ...state,
          updateAnnotationPending: false,
          updateAnnotationFeedback: action.feedback,
          annotations: state.annotations.map((tag) => {
            const updatedAnnotation = annotations.find((annotation) => annotation.id === tag.id);
            return updatedAnnotation
              ? { ...tag, annotation: { ...tag.annotation, ...updatedAnnotation } }
              : tag;
          }),
        };
      } else
        return {
          ...state,
          updateAnnotationPending: false,
          updateAnnotationFeedback: action.feedback,
          annotations: state.annotations.map((a) => {
            const updatedAnnotation = annotations.find((annotation) => annotation.id === a.id);
            return updatedAnnotation ? { ...a, ...updatedAnnotation } : a;
          }),
        };
    }

    case CASE_UPDATE_ANNOTATIONS_SHARE_FAILURE:
      return {
        ...state,
        updateAnnotationsSharePending: false,
        updateAnnotationsShareFeedback: action.feedback,
      };

    case CASE_UPDATE_ANNOTATIONS_SHARE_DISMISS_FEEDBACK:
      return {
        ...state,
        updateAnnotationsShareFeedback: null,
      };

    default:
      return state;
  }
}
