import React, { useState, useEffect, useRef, useMemo } from 'react';
import moment from 'moment';
import {
  Button,
  TextField,
  Popper,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  IconButton,
  Box,
  ClickAwayListener,
  Grow,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { makeStyles } from '@mui/styles';
import T from 'i18n';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import FormattedDateInput from 'features/common/FormControls/FormattedDateInput';
import { useFetchTimelines } from '../redux/fetchTimelines';
import { fetchContainer } from '../redux/fetchContainer';
import { createTimelines } from '../redux/createTimelines';

const useStyles = makeStyles(() => ({
  noBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  formControlLabel: {
    marginLeft: '-8px',
  },
  searchInput: {
    '& .MuiOutlinedInput-root': {
      paddingLeft: 0,
    },
    '&.MuiFormControl-root': {
      marginBottom: '5px',
    },
  },
  consistentPlaceholder: {
    '& .MuiInputBase-input::placeholder': {
      color: 'rgba(0, 0, 0, 0.6)',
      opacity: 0.5,
    },
    '& input::placeholder': {
      color: 'rgba(0, 0, 0, 0.6)',
      opacity: 0.5,
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: 'rgba(0, 0, 0, 0.6)',
      opacity: 0.5,
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 0',
  },
}));

const radioContainerStyles = {
  height: '150px', // Use fixed height instead of min/max
  overflow: 'auto',
  mb: 2,
  // WebKit browsers (Chrome, Safari, Edge)
  '&::-webkit-scrollbar': {
    width: '8px',
    display: 'none',
  },
  '&:hover': {
    '&::-webkit-scrollbar': {
      display: 'block',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.25)',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.35)',
      },
    },
  },
};

interface AddEventContentProps {
  showAddEventModal: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleSave: (timeline: any, data: any) => void;
  mark: any;
  defaultFile: any;
  buttonPosition?: DOMRect | null;
}

const AddEventContent = ({
  showAddEventModal,
  anchorEl,
  handleClose,
  handleSave,
  mark,
  defaultFile,
}: AddEventContentProps) => {
  const { fetchTimelines } = useFetchTimelines();
  const dispatch = useDispatch();

  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [showAddIcon, setShowAddIcon] = useState(false);
  const [timelineOptions, setTimelineOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [creatingTimeline, setCreatingTimeline] = useState(false);
  const [createTimelineError, setCreateTimelineError] = useState<string | null>(null);
  const [fetchTimelineError, setFetchTimelineError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    control,
  } = useForm({
    defaultValues: {
      name: '',
      timelineId: '',
      date: '',
      time: '',
      file: defaultFile,
      fileText: mark ? mark.text : '',
    },
  });

  // Watch values for UI updates
  const selectedTag = watch('timelineId');
  const dateValue = watch('date');
  const eventText = watch('name');

  const searchInputRef = useRef<HTMLInputElement>(null);
  const radioContainerRef = useRef<HTMLDivElement>(null);
  const dateValid = useMemo(() => {
    if (!dateValue) return false;
    return moment(dateValue, 'DD/MM/YYYY', true).isValid();
  }, [dateValue]);

  // Memoize filteredTimelineOptions to prevent unnecessary recalculations
  const filteredTimelineOptions = useMemo(() => {
    return timelineOptions.filter((timeline) =>
      timeline?.name.toLowerCase().includes(searchText?.toLowerCase()),
    );
  }, [timelineOptions, searchText]);
  useEffect(() => {
    if (showAddEventModal) {
      setLoading(true);
      setFetchTimelineError(null);
      fetchTimelines()
        .then((response: React.SetStateAction<any[]>) => {
          // Set state only once
          setTimelineOptions(Array.isArray(response) ? response : []);
        })
        .catch((error: any) => {
          console.error('Error fetching timelines:', error);
          setFetchTimelineError(T.translate('viewing.timelineFetchError'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [showAddEventModal, fetchTimelines]);

  // Scroll selected tag into view
  useEffect(() => {
    if (selectedTag) {
      setTimeout(() => {
        const selectedElement = document.getElementById(`tag-item-${selectedTag}`);
        const container = document.getElementById('radio-container');

        if (selectedElement && container) {
          const containerRect = container.getBoundingClientRect();
          const elementRect = selectedElement.getBoundingClientRect();

          if (elementRect.bottom > containerRect.bottom || elementRect.top < containerRect.top) {
            selectedElement.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }
        }
      }, 150);
    }
  }, [selectedTag]);

  // Focus on search input when modal opens
  useEffect(() => {
    if (showAddEventModal) {
      const timer = setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [showAddEventModal]);

  // Check if searched item exists in timelines
  useEffect(() => {
    if (searchText) {
      const exactMatch = timelineOptions.some(
        (timeline) => timeline.name.toLowerCase() === searchText.toLowerCase(),
      );
      setShowAddIcon(!exactMatch && searchText.trim() !== '');
    } else {
      setShowAddIcon(false);
    }
  }, [searchText, timelineOptions]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleTagSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('timelineId', e.target.value);
  };

  const handleCreateNewTimeline = () => {
    if (!searchText.trim()) return;
    setCreatingTimeline(true);
    setCreateTimelineError(null);

    const timelineData = {
      name: searchText.trim(),
    };
    // Call the createTimelines action
    dispatch(createTimelines({ timelines: timelineData }))
      .then((response: any) => {
        setTimelineOptions([...timelineOptions, response]);
        setValue('timelineId', response.id);

        // Clear search text
        setSearchText('');

        //update the left folder container
        dispatch(fetchContainer({ folder: { type: 'timelines' } }));
        // Scroll to the new element
        setTimeout(() => {
          const container = document.getElementById('radio-container');
          const newItem = document.getElementById(`tag-item-${response.id}`);

          if (container && newItem) {
            container.scrollTop = newItem.offsetTop - container.offsetTop;
          }
        }, 200);
      })
      .catch((error: any) => {
        console.error('Error creating timeline:', error);
        setCreateTimelineError(T.translate('viewing.timelineCreationError'));
      })
      .finally(() => {
        setCreatingTimeline(false);
      });
  };

  const onSubmit = (data: any) => {
    // Format the date
    const dateTime = data.time ? `${data.date} ${data.time}` : data.date;

    // Find the selected timeline from the options (like in AddNewEvent)
    let selectedTimeline;
    if (data.timelineId) {
      // Check in the timelines
      selectedTimeline = timelineOptions.find((item) => item.id === data.timelineId);
    }

    // Create the event data object
    const eventData = {
      title: data.name,
      name: data.name,
      date: dateTime,
      file: defaultFile,
      fileText: mark?.text || '',
    };

    // Call the save handler with the selected timeline and data
    handleSave(selectedTimeline, eventData);
    handleClose();
  };

  return (
    <Popper
      open={showAddEventModal}
      anchorEl={anchorEl}
      placement="bottom-start"
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            boundary: 'viewport',
            altAxis: true,
            padding: 24,
          },
        },
        {
          name: 'flip',
          enabled: true,
          options: {
            fallbackPlacements: ['top-start', 'top-end', 'bottom-end'],
            altBoundary: false,
            rootBoundary: 'viewport',
            padding: 24,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [8, 16],
          },
        },
        {
          name: 'arrow',
          enabled: false,
        },
        {
          name: 'maxSize',
          enabled: true,
          options: {
            padding: 24,
            boundary: 'viewport',
          },
        },
      ]}
      style={{
        zIndex: 1300,
        maxWidth: 'calc(100vw - 48px)',
        maxHeight: 'calc(100vh - 48px)',
        width: 350,
      }}
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={250} style={{ transformOrigin: 'top center' }}>
          <div
            style={{
              maxHeight: 'calc(100vh - 32px)',
              overflowY: 'scroll',
              // Prevent the overflow from cutting off the shadow
              padding: '8px',
              // Move padding from the wrapper to ensure shadows are visible
              boxSizing: 'border-box',
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper
                elevation={4}
                sx={{
                  p: 2,
                  width: '100%',
                  borderRadius: '0.5rem',
                  border: 'none',
                  margin: '1px',
                  overflow: 'visible',
                  transition: 'box-shadow 0.2s ease-in-out, filter 0.2s ease-in-out',
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    autoFocus
                    fullWidth
                    className={classes.searchInput}
                    size="small"
                    value={searchText}
                    onChange={handleSearchChange}
                    placeholder={T.translate('viewing.searchTimeLine')}
                    variant="outlined"
                    autoComplete="off"
                    inputRef={searchInputRef}
                    sx={{ mb: 2 }}
                    InputProps={{
                      className: classes.noBorder,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: showAddIcon && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleCreateNewTimeline}
                            edge="end"
                            size="small"
                            disabled={creatingTimeline}
                          >
                            {creatingTimeline ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              <AddBoxIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* Show timeline fetch error if present */}
                  {fetchTimelineError && (
                    <Box sx={{ mb: 1, color: 'error.main', fontSize: '1rem' }}>
                      {fetchTimelineError}
                    </Box>
                  )}

                  {/* Show timeline creation error if present */}
                  {createTimelineError && (
                    <Box sx={{ mb: 1, color: 'error.main', fontSize: '1rem' }}>
                      {createTimelineError}
                    </Box>
                  )}
                  {/* Radio options with scrollable container */}
                  <Box id="radio-container" ref={radioContainerRef} sx={radioContainerStyles}>
                    {loading ? (
                      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress size={24} />
                      </Box>
                    ) : filteredTimelineOptions.length > 0 ? (
                      <Controller
                        name="timelineId"
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                              handleTagSelect(e as React.ChangeEvent<HTMLInputElement>);
                            }}
                          >
                            {filteredTimelineOptions.map((timeline) => (
                              <div id={`tag-item-${timeline.id}`} key={timeline.id}>
                                <FormControlLabel
                                  className={classes.formControlLabel}
                                  value={timeline.id}
                                  control={<Radio size="small" />}
                                  label={timeline.name}
                                />
                              </div>
                            ))}
                          </RadioGroup>
                        )}
                      />
                    ) : (
                      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                          {searchText
                            ? `${T.translate('viewing.noMatchingTimelines')}`
                            : `${T.translate('viewing.noTimelinesAvailable')}`}
                        </span>
                      </Box>
                    )}
                  </Box>

                  <Box sx={{ height: 8 }} />

                  <Box className={classes.consistentPlaceholder}>
                    <FormattedDateInput
                      dateTimeVal={{
                        date: dateValue,
                      }}
                      setDateTimeVal={(vals: { date: string }) => {
                        setValue('date', vals.date);
                      }}
                      errors={errors}
                      trigger={() => trigger('date')}
                      register={register}
                      focusDateField={false}
                    />
                  </Box>

                  <Box sx={{ height: 16 }} />

                  <TextField
                    {...register('name', {
                      required: false,
                      onBlur: (e) => {
                        const trimmedValue = e.target.value.trim();
                        setValue('name', trimmedValue);
                      },
                    })}
                    multiline
                    fullWidth
                    className={classes.consistentPlaceholder}
                    rows={2}
                    size="small"
                    placeholder={T.translate('case.eventNamePlaceholder')}
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />

                  {/* Action buttons */}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="inherit"
                        size="small"
                        sx={{
                          color: '#fff',
                          bgcolor: 'text.disabled',
                          ':hover': { bgcolor: 'text.secondary' },
                        }}
                      >
                        <span>{T.translate('generic.close')}</span>
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={
                          !selectedTag ||
                          loading ||
                          creatingTimeline ||
                          !dateValid ||
                          !(eventText && eventText.trim())
                        }
                        size="small"
                      >
                        <span>{T.translate('generic.add')}</span>
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Paper>
            </ClickAwayListener>
          </div>
        </Grow>
      )}
    </Popper>
  );
};

export default AddEventContent;
